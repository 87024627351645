import Vue from 'vue';
import * as momentTemp from 'moment';
import moment from "moment";

// 숫자 천단위에 , 찍기
Vue.filter('makeComma', (str) => {
    if (str === null) return 0;
    return String(Math.floor(str)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})

// 글자 10 글자 이상시 ... 말줄임 처리
Vue.filter('substring',(str, length=14) => {
    let maxLength = length;
    if (str.length < maxLength) {
        return str;
    }
    return str.substring(0, maxLength) + '...';
})

// 글자 10 글자 이상시 ... 말줄임 처리
Vue.filter('amtKor',(val) => {
    const amt = val / 10000;
    return `${amt}만`
})

// 베팅상태값 표시
Vue.filter('cashReasonFilter', (cash) => {
    let reason = '';
    reason = cash.cash_reason;
    if (cash.cash_reason?.includes('롤링') && cash.cash_reason?.includes('지급')) reason = '베팅 롤링'
    if (cash.cash_reason?.includes('베팅 취소 코드')) reason = '취소로 인한 롤링 차감';
    if (cash.cash_detail_type === '수동-포인트') reason = '관리자';
    return reason;
})

// 베팅상태값 표시
Vue.filter('bettingStatus', (str) => {
    let msg = '';
    if (str === 'exception'){
        msg = '적중특례'
    }

    if (str === 'wait'){
        msg = '대기'
    }

    if (str === 'hit'){
        msg = '당첨'
    }

    if (str === 'miss'){
        msg = '낙첨'
    }

    return msg;
})

Vue.filter('sportsStatus', (str) =>{
    let msg = '';
    if (str === 'exception'){
        msg = '적중특례'
    }

    if (str === 'wait'){
        msg = '대기'
    }

    if (str === 'hit'){
        msg = '승'
    }

    if (str === 'miss'){
        msg = '패'
    }

    return msg;
});

Vue.filter('cartBetType', (str) =>{
    let msg = '';
    switch(str){
        case '1':
            msg = '승';
            break
        case 'X':
            msg = '무';
            break
        case '2':
            msg = '패';
            break
        case 'Under':
            msg = '언더';
            break
        case 'Over':
            msg = '오버';
            break
        case '홀':
            msg = '홀';
            break
        case '짝':
            msg = '짝';
            break
        case '언더':
            msg = '언더';
            break;
        case '오버':
            msg = '오버';
            break;
        case '소':
            msg = '소';
            break;
        case '중':
            msg = '중';
            break;
        case '대':
            msg = '대';
            break;
        case '1X':
            msg = '1팀승 또는 무승부';
            break;
        case '12':
            msg = '1팀승 또는 2팀승';
            break;
        case 'X2':
            msg = '무승부 또는 2팀승';
            break;
        default:
            msg = str;
    }
    return msg;
});

Vue.filter('europeanBetNameType', (str) =>{
    let msg = '';
    switch(str){
        case 'X And Under':
            msg = '무승부 언더';
            break
        case 'X And Over':
            msg = '무승부 오버';
            break
        case '1 And Over':
            msg = '승 오버';
            break
        case '1 And Under':
            msg = '승 언더';
            break
        case '2 And Over':
            msg = '패 오버';
            break
        case '2 And Under':
            msg = '패 언더';
            break
        default:
            msg = str;
    }
    return msg;
});


Vue.filter('formatDate',(value, format)=>{
    if (value === null || value === undefined || format === undefined) {
        return ''
    }
    if (format === 'from') {
        return momentTemp(value).fromNow()
    }

    if (format === '요일') {
        let 요일 = '';
        const date = momentTemp(value).subtract(9, 'hours').weekday()
        // console.log(date)
        switch (date){
            case 1:
                요일 = '월'
                break;
            case 2:
                요일 = '화'
                break;
            case 3:
                요일 = '수'
                break;
            case 4:
                요일 = '목'
                break;
            case 5:
                요일 = '금'
                break;
            case 6:
                요일 = '토'
                break;
            case 7:
                요일 = '일'
                break;
        }
        return 요일
    }

    return momentTemp(value).subtract(9, 'hours').format(format)
})

Vue.filter('formatDate1',(value, format)=>{
    if (value === null || value === undefined || format === undefined) {
        return ''
    }
    if (format === 'from') {
        return momentTemp(value).fromNow()
    }

    if (format === '요일') {
        let 요일 = '';
        const date = momentTemp(value).weekday()
        // console.log(date)
        switch (date){
            case 1:
                요일 = '월'
                break;
            case 2:
                요일 = '화'
                break;
            case 3:
                요일 = '수'
                break;
            case 4:
                요일 = '목'
                break;
            case 5:
                요일 = '금'
                break;
            case 6:
                요일 = '토'
                break;
            case 7:
                요일 = '일'
                break;
        }
        return 요일
    }

    return momentTemp(value).format(format)
})
Vue.filter('subtractDate',(after, before)=>{
    if (after === null || after === undefined || after === undefined) {
        return ''
    }
    let beforeDay = momentTemp(before);
    let afterDay = momentTemp(after);

    let gap = momentTemp.duration(afterDay.diff(beforeDay))
    return `${gap.hours()}:${gap.minutes()}:${gap.seconds()}`
})

Vue.filter('twoDigits',(value)=>{
    if (value.toString().length <= 1) {
        return '0' + value.toString()
    }
    return value.toString()
})

Vue.filter('countDown',(time)=>{
    let resultTime = time - 5;
    let minute = '00';
    let second = '00';
    if (resultTime <= 0) return '00:00';
    minute = Math.floor(resultTime / 60)
    second = resultTime % 60;
    if (minute.toString().length <= 1){
        minute = '0' + minute.toString()
    }
    if (second.toString().length <= 1){
        second = '0' + second.toString()
    }
    return minute + ':' + second
})



Vue.filter('displayType',(type)=>{
    let dis = ''
    if(type === 1){
        dis = '승무패'
    }
    if(type === 2){
        dis = '오버언더'
    }
    if(type === 3){
        dis = '핸디캡'
    }
    if(type === 21){
        dis = '오버언더(1피리어드)'
    }
    if(type === 28){
        dis = '오버언더(연장포함)'
    }
    if(type === 41){
        dis = '승무패(1쿼터)'
    }
    if(type === 52){
        dis = '승패'
    }
    if(type === 64){
        dis = '핸디캡(1쿼터)'
    }
    if(type === 165){
        dis = '언더오버(세트)'
    }
    if(type === 202){
        dis = '승패(1세트)'
    }
    if(type === 226){
        dis = '승패(연장포함)'
    }
    if(type === 235){
        dis = '승패(5이닝)'
    }
    if(type === 236){
        dis = '오버언더(5이닝)'
    }
    if(type === 281){
        dis = '핸디캡(5이닝)'
    }
    if(type === 342){
        dis = '핸디캡(연장포함)'
    }
    if(type === 866){
        dis = '핸디캡(세트)'
    }
    if(type === 1558){
        dis = '핸디캡(포인트)'
    }

    return dis
})

Vue.filter('currentPeriodFilter', (row) =>{
    let result = '';
    // const list = [
    //     {sports_id: 6046, name: '축구'},
    //     {sports_id: 35232, name: '아이스하키'},
    //     {sports_id: 48242, name: '농구'},
    //     {sports_id: 154830, name:'배구'},
    //     {sports_id: 154914, name: '야구'},
    //     {sports_id: 131506, name: '미식축구'},
    //     {sports_id: 687890, name: '이스포츠'}];
    const currentPeriod = row.game_current_period;
    const sport = row.game_sport;
    if (row.game_status === '대기'){
        result = '대기'
        return result;
    }
    // 축구
    if (sport === 6046) {
        if (currentPeriod === 1) result = '전반전'
        if (currentPeriod === 2) result = '후반전'
        if (currentPeriod === 101) result = 'OT'
    }

    // 하키
    if (sport === 35232) {
        if (currentPeriod === 1) result = '1피어리어드'
        if (currentPeriod === 2) result = '2피어리어드'
        if (currentPeriod === 3) result = '3피어리어드'

        if (currentPeriod === 100) result = '종료'
        if (currentPeriod === 101) result = 'OT'
    }

    if (sport === 48242) {
        if (currentPeriod === 1) result = '1쿼터'
        if (currentPeriod === 2) result = '2쿼터'
        if (currentPeriod === 3) result = '3쿼터'
        if (currentPeriod === 4) result = '4쿼터'

        if (currentPeriod === 100) result = '종료'
        if (currentPeriod === 101) result = 'OT'
    }

    if (sport === 154830) {
        if (currentPeriod === 1) result = '1세트'
        if (currentPeriod === 2) result = '2세트'
        if (currentPeriod === 3) result = '3세트'
        if (currentPeriod === 4) result = '4세트'
        if (currentPeriod === 5) result = '5세트'
        if (currentPeriod === 100) result = '종료'
    }

    if (sport === 131506) {
        if (currentPeriod === 1) result = '1쿼터'
        if (currentPeriod === 2) result = '2쿼터'
        if (currentPeriod === 3) result = '3쿼터'
        if (currentPeriod === 4) result = '4쿼터'

        if (currentPeriod === 100) result = '종료'
        if (currentPeriod === 101) result = 'OT'
    }
    return result;
});

Vue.filter('inplayLineFilter', (line, type) =>{
    return type === 'home' ? line.split(' ')[0] : line.split(' ')[0] * -1
});

Vue.filter('timeBySecondsFilter', (seconds) =>{
    const min = parseInt((seconds%3600)/60);
    const sec = seconds % 60;

    return `${min}:${sec}`
});

Vue.filter('scoreFilter', (currentPeriod, sport) =>{
    let result = '';
    // 축구
    if (sport === 6046) {
        if (currentPeriod === 1) result = '전반전'
        if (currentPeriod === 2) result = '후반전'
        if (currentPeriod === 101) result = 'OT'
    }

    // 하키
    if (sport === 35232) {
        if (currentPeriod === 1) result = '1피어리어드'
        if (currentPeriod === 2) result = '2피어리어드'
        if (currentPeriod === 3) result = '3피어리어드'

        if (currentPeriod === 100) result = '종료'
        if (currentPeriod === 101) result = 'OT'
    }

    if (sport === 48242) {
        if (currentPeriod === 1) result = '1쿼터'
        if (currentPeriod === 2) result = '2쿼터'
        if (currentPeriod === 3) result = '3쿼터'
        if (currentPeriod === 4) result = '4쿼터'

        if (currentPeriod === 100) result = '종료'
        if (currentPeriod === 101) result = 'OT'
    }

    if (sport === 154830) {
        if (currentPeriod === 1) result = '1세트'
        if (currentPeriod === 2) result = '2세트'
        if (currentPeriod === 3) result = '3세트'
        if (currentPeriod === 4) result = '4세트'
        if (currentPeriod === 5) result = '5세트'
        if (currentPeriod === 100) result = '종료'
    }

    if (sport === 131506) {
        if (currentPeriod === 1) result = '1쿼터'
        if (currentPeriod === 2) result = '2쿼터'
        if (currentPeriod === 3) result = '3쿼터'
        if (currentPeriod === 4) result = '4쿼터'

        if (currentPeriod === 100) result = '종료'
    }
    return result;
});


// 베팅상태값 표시
Vue.filter('bettingStatus', (str) => {
    let msg = '';
    if (str === 'exception'){
        msg = '적중특례'
    }

    if (str === 'wait'){
        msg = '대기'
    }

    if (str === 'hit'){
        msg = '당첨'
    }

    if (str === 'miss'){
        msg = '낙첨'
    }

    return msg;
})

Vue.filter('gameResult', (str) =>{
    let msg = '';
    if (str === 'home'){
        msg = '승'
    }

    if (str === 'draw'){
        msg = '무'
    }

    if (str === 'exception'){
        msg = '적특'
    }

    if (str === 'away'){
        msg = '패'
    }

    return msg;
});

//미니게임 이름 변경
Vue.filter('miniGameName',(name) => {
    let gameName = '';
    switch (name){
        case 'powerball':
            gameName = '파워볼'
            break;
        case 'powerladder':
            gameName = '파워사다리'
            break;
        case 'kenoladder':
            gameName = '키노사다리'
            break;
    }

    return gameName;
})

// //마켓 이름 필터
// Vue.filter('sportName',(list) => {
//     let name = ''
//     switch (list){
//         case 6046:
//             name = '축구';
//             break;
//         case 48242:
//             name = '농구';
//             break;
//         case 154830:
//             name = '배구';
//             break;
//         case 687890:
//             name = 'e스포츠';
//             break;
//         case 35232:
//             name = '아이스하키';
//             break;
//         case 154914:
//             name = '야구';
//             break;
//         case 131506:
//             name = '미식축구';
//             break;
//     }
//     return name;
// })

//마켓 폴더 필터 이름
Vue.filter('folderFilter',(line, marketName) => {
    let name = ''
    if (!line) {
        name = 'VS'
        return name;
    }
    name = line;
    if (marketName.indexOf('Handicap') >= 0){
        name = line.split(' ')[0]
    }
    return name;
})


//마켓 폴더 필터 이름
Vue.filter('currentPeriod',(period, sports) => {
    let name = '';
    if (period === 0) {
        name = '시작전'
    }
    // if (period === 80) name = '휴식'
    switch (sports){
        case 6046:
            if (period === 1) name = `전반전`
            if (period === 2) name = `후반전`
            break;
        case 48242:
            name = `${period} 쿼터`
            break;
        case 35232:
            name = `${period} 피어리드`
            break;
        case 154830:
            name = `${period} 세트`
            break;
        case 154914:
            name = `${period} 회`
            if (period > 10){
                name = '연장'
            }
            break;
        case 131506:
            name = `${period} 쿼터`
            break;
        case 687890:
            name = `${period} 세트`;
            break
    }

    if (period === 0) {
        name = '시작전'
    }

    if (period === 100) {
        name = '종료'
    }

    return name;
})

Vue.filter('currentPeriod2',(period, sports) => {
    let name = '';
    if (Number(period) === 0) return '시작전';
    switch (sports){
        case 210:
            if (Number(period) === 1) name = `전반전`
            if (Number(period) === 2) name = `후반전`
            break;
        case 212:
            name = `${period} 쿼터`
            if (Number(period) > 4) name = '연장';
            break;
        case 211:
            name = `${period} 피어리드`
            if (Number(period) > 3) name = '연장';
            break;
        case 214:
            name = `${period} 세트`
            break;
        case 220:
            name = `${period} 회`
            if (Number(period) > 9) name = '연장'
            break;
        case 215:
            name = `${period} 쿼터`
            if (Number(period) > 4) name = '연장';
            break;
        case 267 || 272 || 273:
            name = `${period} 세트`;
            break
    }

    if (period === 100) {
        name = '종료'
    }

    return name;
})

Vue.filter('currentPeriod3',(game, type = 'period') => {
    let name = '';
    if (type === 'period') {
        if (game.status === '대기' || !game.subResult || game.subResult === '') return '시작전';
    }
    if (!game.subResult || game.subResult === '') return 0;

    if (game.sportId === 1) {
        // console.log(detailResult);
        if (type === 'period') {
            const detailResult = game.subResult?.split(' ');
            const periodResult = detailResult.filter(e => e.indexOf('(') >= 0);
            if (periodResult.length === 0) name = '전반전';
            if (periodResult.length === 1) name = `전반전`
            if (periodResult.length === 2) name = `후반전`
        }
        if (type === 'time') {
            const detailResult = game.subResult?.split(' ');
            const time = detailResult[detailResult.length - 1].replace('`', ' 분');
            name = time;
        }
        if (type === 'homeTotalScore') {
            const detailResult = game.subResult?.split(' ');
            const score = detailResult[0];
            name = score;
        }
        if (type === 'awayTotalScore') {
            const detailResult = game.subResult?.split(' ');
            const score = detailResult[2].replace(',','').replace(' ', '');
            name = score;
        }
    }
    if (game.sportId === 2) {
        if (type === 'period') {
            if (!game.subResult || game.subResult === '') return '1 피리어드';
            const detailResult = game.subResult?.split(',');
            const periodResult = detailResult.length;
            name = `${periodResult} 피리어드`
        }
        if (type === 'homeTotalScore') {
            if (!game.subResult || game.subResult === '') return 0;
            const detailResult = game.subResult?.split(' ');
            const score = detailResult[0];
            const result = score.split(':')[0];
            name = result;
        }

        if (type === 'awayTotalScore') {
            if (!game.subResult || game.subResult === '') return 0;
            const detailResult = game.subResult?.split(' ');
            const score = detailResult[0];
            const result = score?.split(':')[1]?.replace(',', '').replace(' ', '');
            name = result;
        }
        if (type === 'time') {
            if (!game.subResult || game.subResult === '') return '20:00';
            const detailResult = game.subResult?.split(' ');
            const time = detailResult[detailResult.length - 1];
            name = time;
        }
    }
    if (game.sportId === 3) {
        if (type === 'period') {
            if (!game.subResult || game.subResult === '') return '1 쿼터';
            const detailResult = game.subResult?.split(' ');
            const periodResult = detailResult.filter(e => e.indexOf('(') >= 0);
            name = `${periodResult.length} 쿼터`
        }
        if (type === 'time') {
            if (!game.subResult || game.subResult === '') return '12:00';
            const detailResult = game.subResult?.split(' ');
            const time = detailResult[detailResult.length - 1];
            name = time;
        }
        if (type === 'homeTotalScore') {
            if (!game.subResult || game.subResult === '') return 0;
            const detailResult = game.subResult?.split(' ');
            const score = detailResult[0];
            const result = score.split(':')[0];
            name = result;
        }

        if (type === 'awayTotalScore') {
            if (!game.subResult || game.subResult === '') return 0;
            const detailResult = game.subResult?.split(' ');
            const score = detailResult[0];
            const result = score?.split(':')[1].replace(',', '').replace(' ', '');
            name = result;
        }
    }
    if (game.sportId === 5) {
        if (type === 'period') {
            if (game.status === '대기' || !game.subResult || game.subResult === '') return '시작전';
            if (!game.subResult || game.subResult === '') return '1 세트';
            const detailResult = game.subResult?.split(',');
            const periodResult = detailResult.filter(e => e.indexOf('(') >= 0);
            name = `${periodResult.length} 세트`
        }

        if (type === 'homeTotalScore') {
            if (!game.subResult || game.subResult === '') return 0;
            const detailResult = game.subResult?.split(' ');
            const score = detailResult[0];
            const result = score.split(':')[0];
            name = result;
        }

        if (type === 'awayTotalScore') {
            if (!game.subResult || game.subResult === '') return 0;
            const detailResult = game.subResult?.split(' ');
            const score = detailResult[0];
            const result = score?.split(':')[1].replace(',', '').replace(' ', '');
            name = result;
        }
    }
    if (game.sportId === 6) {
        console.log(game.subResult)
    }
    if (game.sportId === 11) {
        if (type === 'period') {
            // console.log(!game.subResult);
            if (!game.subResult || game.subResult === '') return '1회';
            const detailResult = game.subResult?.split(',');
            const periodResult = detailResult.filter(e => e.indexOf('(') >= 0);
            name = `${periodResult.length} 회`
        }
        if (type === 'homeTotalScore') {
            if (!game.subResult && game.subResult === '') return 0;
            const detailResult = game.subResult?.split(',');
            const score = detailResult[0];
            const result = score.split(':')[0];
            name = result;
        }

        if (type === 'awayTotalScore') {
            if (!game.subResult || game.subResult === '') return 0;
            const detailResult = game.subResult?.split(',');
            const score = detailResult[0];
            const result = score?.split(':')[1].replace(',', '').replace(' ', '');
            name = result;
        }
    }
    if ([77, 82, 83].indexOf(game.sportId) >= 0) {
        if (type === 'period') {
            if (!game.subResult || game.subResult === '') return '1 세트';
            const detailResult = game.subResult?.split(',');
            const periodResult = detailResult.filter(e => e.indexOf('(') >= 0);
            name = `${periodResult.length === 0 ? 1 : periodResult.length} 세트`
        }

        if (type === 'homeTotalScore') {
            if (!game.subResult || game.subResult === '') return '0';
            const detailResult = game.subResult?.split(' ');
            const score = detailResult[1];
            let result = score?.split(':');
            if (!result) result = 0;
            if (result) result = result[0]
            name = result;
        }

        if (type === 'awayTotalScore') {
            const detailResult = game.subResult?.split(' ');
            if (!game.subResult || game.subResult === '') return '0';
            const score = detailResult[1];
            let result = score?.split(':');
            if (!result) result = 0;
            if (result) result = result[1].replace(',', '').replace(' ', '')
            name = result;
        }
    }

    return name;
})

Vue.filter('currentPeriodLsportInplay',(game, type = 'period') => {
    let name = '';
    if (type === 'period') {
        if (game.status === '대기') return '시작전';
    }

    if (game.sportId == 6046) {
        // console.log(detailResult);
        if (type === 'period') {
            if (game.currentPeriod === 10) name = '전반전';
            if (game.currentPeriod === 20) name = '후반전';
        }
    }
    if (game.sportId == 35232) {
        if (type === 'period') {
            name = `${game.currentPeriod} 피리어드`
        }
    }
    if (game.sportId == 48242) {
        if (type === 'period') {
            name = `${game.currentPeriod} 쿼터`;
            if (game.currentPeriod === 80) name = '하프타임';
        }
    }
    if (game.sportId == 154830) {
        if (type === 'period') {
            name = `${game.currentPeriod} 세트`
        }
    }
    if (game.sportSeq === 215) {
        console.log(game.subResult)
    }
    if (game.sportId == 154914) {
        if (type === 'period') {
            name = `${game.currentPeriod} 회`
        }
    }
    if ([687890].indexOf(game.sportId) >= 0) {
        if (type === 'period') {
            name = `${game.currentPeriod} 세트`
        }
    }
    if (Number(game.currentPeriod) === 100) name = '종료';
    console.log(Number(game.currentPeriod) === 100, '피리어드체크')
    return name;
})


Vue.filter('periodScore',(game, type = '1') => {
    let name = '';
    if (game.status === '대기') return '시작전';
    if (game.sportSeq === 210) {
        const detailResult = game.subResult?.split(' ');
        console.log(detailResult);
        if (type === 'homeTotalScore') {
            const score = detailResult[0];
            name = score;
        }

        if (type === 'awayTotalScore') {
            const score = detailResult[2];
            name = score;
        }
        if (type === 'time') {
            const time = detailResult[detailResult.length - 1];
            name = time;
        }
    }
    if (game.sportSeq === 211) {
        const detailResult = game.subResult?.split(' ');
        if (type === 'homeTotalScore') {
            const score = detailResult[0];
            const result = score.split(':')[0];
            name = result;
        }

        if (type === 'awayTotalScore') {
            const score = detailResult[0];
            const result = score?.split(':')[1]?.replace(',', '').replace(' ', '');
            name = result;
        }
        if (type === 'time') {
            const time = detailResult[detailResult.length - 1];
            name = time;
        }
    }
    if (game.sportSeq === 212) {
        const detailResult = game.subResult?.split(' ');
        console.log(detailResult);
    }
    if (game.sportSeq === 214) {
        console.log(game.subResult)
    }
    if (game.sportSeq === 215) {
        console.log(game.subResult)

    }
    if (game.sportSeq === 220) {
        console.log(game.subResult)
    }
    if ([267, 272, 273].indexOf(game.sportSeq) >= 0) {
        console.log(game.subResult)
    }

    return name;
})


Vue.filter('periodDetail',(period, sportId) => {
    let name = '';
    if (sportId === 1) {
        if(period === 1) name = `전반전`;
        if(period === 2) name = `후반전`;
    }
    if (sportId === 3 || sportId === 6) name = `${period} 쿼터`;
    if (sportId === 2) name = `${period} 피리어드`;
    if (sportId === 5 || sportId === 77) name = `${period} 세트`;
    if (sportId === 11) name = `${period} 이닝`;

    return name;
})


Vue.filter('periodDetailLsport',(period, sportId) => {
    let name = '';
    if (sportId === 6046) {
        if(period === 1) name = `전반전`;
        if(period === 2) name = `후반전`;
    }
    if (sportId === 48242 || sportId === 131506) name = `${period} 쿼터`;
    if (sportId === 35232) name = `${period} 피리어드`;
    if (sportId === 154830 || sportId === 687890) name = `${period} 세트`;
    if (sportId === 154914) name = `${period} 이닝`;

    return name;
})





Vue.filter('makeIndex', (index, page, size) => {
    return ((page - 1) * size) + (index+1)
})

//마켓 이름 필터
Vue.filter('teamMarketName',(market, homeTeam = null, awayTeam = null) => {
    if (market.nameKr.indexOf('1팀') >= 0){
        return `${market.nameKr || market.nameEn}`.replace('1팀', homeTeam.nameKr || homeTeam.nameEn)
    }

    if (market.nameKr.indexOf('2팀') >= 0){
        return `${market.nameKr || market.nameEn}`.replace('2팀', awayTeam.nameKr || awayTeam.nameEn)
    }
    return market.nameKr || market.nameEn
})

Vue.filter('teamMarketNamePeriod',(market, homeTeam = null, awayTeam = null, period) => {
    let name = market.nameKr || market.nameEn;
    if (String(period) !== '0'){
        name = `${market.nameKr || market.nameEn}`.replace('{sw}', period)
    }
    if (market.nameKr.indexOf('1팀') >= 0){
        name = name.replace('1팀', homeTeam.nameKr || homeTeam.nameEn)
    }

    if (market.nameKr.indexOf('2팀') >= 0){
        name =  name.replace('2팀', awayTeam.nameKr || awayTeam.nameEn)
    }
    return name
})

//마켓 이름 필터
Vue.filter('marketName',(market, periodSeq = '0') => {
    if (!market) return '';
    if (String(periodSeq) !== '0'){
        return `${market.nameKr || market.nameEn}`.replace('{sw}', periodSeq)
    }
    return market.nameKr || market.nameEn;
})

Vue.filter('marketNameLsport',(market, game = null) => {
    if (!market) return '';
    let name = market.nameKr || market.nameEn;
    console.log(game.sport.periodName);
    if (game.sport.periodName === '반전') {
        name = name.replace('1{sw}', '전반전')
        name = name.replace('2{sw}', '후반전')
        return name
    }
    if (name.includes('1팀') || name.includes('홈팀')){
        name = name.replace('1팀', game.homeTeam.nameKr || game.homeTeam.nameEn)
        name = name.replace('홈팀', game.homeTeam.nameKr || game.homeTeam.nameEn)
    }
    if (name.includes('2팀') || name.includes('원정팀')){
        name = name.replace('2팀', game.awayTeam.nameKr || game.awayTeam.nameEn)
        name = name.replace('원정팀', game.awayTeam.nameKr || game.awayTeam.nameEn)

    }
    return name.replace('{sw}', game.sport.periodName)
})


Vue.filter('categorize',(category) => {
    if (!category) return '기타';
    if (category === 1) return '일반'
    if (category === 2) return '이벤트'
    if (category === 3) return '공지'
})

//리그 이름 필터
Vue.filter('leagueName',(league) => {
    if (!league) return '';
    return league ? league.nameKr || league.nameEn : '';
})

//국가 이름 필터
Vue.filter('locationName',(location) => {
    if (!location) return '';
    return location ? location.nameKr || location.nameEn : '';
})

//국가 이름 필터
Vue.filter('sportName',(sport) => {
    return sport ? sport.nameKr || sport.nameEn : '';
})

//국가 이름 필터
Vue.filter('teamName',(team) => {
    return team ? team.nameKr || team.nameEn : '';
})


//마켓 이름 필터
Vue.filter('marketName2',(market) => {
    if (!market) return '';
    return market.markets_name_kr1 || market.markets_name_en
})

//리그 이름 필터
Vue.filter('leagueName2',(league) => {
    if (!league) return '';
    return league.leagues_name_kr1 || league.leagues_name_en;
})

//국가 이름 필터
Vue.filter('locationName2',(location) => {
    if (!location) return '';
    return location.locations_name_kr1 || location.locations_name_en;
})

//국가 이름 필터
Vue.filter('sportName2',(sport) => {
    return sport.sports_name_kr1 || sport.sports_name_en;
})

//국가 이름 필터
Vue.filter('teamName2',(team) => {
    return team.teams_name_kr1 || team.teams_name_en;
})


//라인 설정
Vue.filter('lineSet',(line) => {
    if (!line) return '';
    if (line.split(' ')) return line.split(' ')[0]
    return line
})

Vue.filter('lineSet2',(line, type='1') => {
    if (!line) return '';
    if (type === '1')if (line.split(' ')) return line.split(' ')[0];
    if (type === '2')if (line.split(' ')) return Number(line.split(' ')[0]) * -1;
    return line
})

//라인 설정
Vue.filter('europeanDate',(date) => {
    // 모먼트 오브젝트
    let result = '';
    result += date.format('MM-DD')
    if (date.day() === 0) result += ' (월)'
    if (date.day() === 1) result += ' (화)'
    if (date.day() === 2) result += ' (수)'
    if (date.day() === 3) result += ' (목)'
    if (date.day() === 4) result += ' (금)'
    if (date.day() === 5) result += ' (토)'
    if (date.day() === 6) result += ' (일)'
    return result
})


//라인 설정
Vue.filter('periodName',(market, sport) => {
    let name = '';
    switch (sport) {
        case 6046:
            if (market.markets_id === 21) name = '(전반전)';
            if (market.markets_id === 41) name = '(전반전)';
            if (market.markets_id === 64) name = '(전반전)';
            break;
        case 48242:
            if (market.markets_id === 21) name = '(1쿼터)';
            if (market.markets_id === 64) name = '(1쿼터)';
            if (market.markets_id === 41) name = '(1쿼터)';
            break;
        case 154830:
            if (market.markets_id === 2) name = '(오버언더 총합)';
            if (market.markets_id === 21) name = '(1세트)';
            if (market.markets_id === 64) name = '(1세트)';
            if (market.markets_id === 202) name = '(1세트)';
            if (market.markets_id === 165) name = '(오버언더 세트)';
            if (market.markets_id === 866) name = '(핸디캡 세트)';
            if (market.markets_id === 1558) name = '(핸디캡 총합)';
            break;
        case 687890:
            if (market.markets_id === 202) name = '(1세트)';
            break;
        case 35232:
            if (market.markets_id === 21) name = '(1피리어드)';
            if (market.markets_id === 64) name = '(1피리어드)';
            break;
        case 154914:
            if (market.markets_id === 21) name = '(1이닝)';
            if (market.markets_id === 64) name = '(1이닝)';
            if (market.markets_id === 235) name = '(5이닝)';
            if (market.markets_id === 236) name = '(5이닝)';
            if (market.markets_id === 281) name = '(5이닝)';
            break;
        case 131506:
            break;
    }
    return name;
})

// 글자 마스킹
Vue.filter('strMasking',(str) => {
    let originStr = str;
    let phoneStr;
    let maskingStr;

    if(!originStr){
        return originStr;
    }

    if (originStr.toString().split('-').length != 3)
    { // 1) -가 없는 경우
        phoneStr = originStr.length < 11 ? originStr.match(/\d{10}/gi) : originStr.match(/\d{11}/gi);
        if(!phoneStr){
            return originStr;
        }

        if(originStr.length < 11)
        { // 1.1) 0110000000
            maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{3})(\d{4})/gi,'$1********$3'));
        }
        else
        { // 1.2) 01000000000
            maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/(\d{3})(\d{4})(\d{4})/gi,'$1********$3'));
        }
    }else
    { // 2) -가 있는 경우
        phoneStr = originStr.match(/\d{2,3}-\d{3,4}-\d{4}/gi);
        if(!phoneStr){
            return originStr;
        }

        if(/-[0-9]{3}-/.test(phoneStr))
        { // 2.1) 00-000-0000
            maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{3}-/g, "-***-"));
        } else if(/-[0-9]{4}-/.test(phoneStr))
        { // 2.2) 00-0000-0000
            maskingStr = originStr.toString().replace(phoneStr, phoneStr.toString().replace(/-[0-9]{4}-/g, "-****-"));
        }
    }

    return maskingStr;
})

//국가 이름 필터
Vue.filter('floorNum',(num) => {
    return Math.floor(num)
})

// 배열 중복 제거
Vue.filter('uniq',(array) => {
    return Array.from(new Set(array));
})

// 시작시간 설정
Vue.filter('gameStarted',(date, endTime) => {
    let now = moment().add(9, 'hour');
    let startDate = moment(date);
    const remainSeconds = startDate.diff(now, 'seconds');
    return endTime >= remainSeconds;
})

// 시작시간 설정
Vue.filter('minute',(date) => {
    const result = date.split('T')[1];
    const minute = result.split(':');

    return `${minute[0]}:${minute[1]}`
})


Vue.filter('betsName',(name) => {
    let result = '승';
    if (name === '1') result = '승';
    if (name === '2') result = '패';
    if (name === 'W1') result = '승';
    if (name === 'W2') result = '패';
    if (name === 'X') result = '무';
    if (name === 'Home') result = '승';
    if (name === 'Away') result = '패';
    if (name === 'Over') result = '오버';
    if (name === 'Under') result = '언더';
    if (name === '1X') result = '홈팀 또는 무승부';
    if (name === '12') result = '홈팀 또는 원정팀';
    if (name === 'X2') result = '무승부 또는 원정팀';
    if (name === 'Odd') result = '홀';
    if (name === 'Even') result = '짝';
    if (name === 'No Goal') result = '노골';
    if (name === 'AnyOther') return '기타';

    return result;
})


Vue.filter('manyBetsName',(name) => {
    let result = '승';
    if (name === '1') result = '승';
    if (name === '2') result = '패';
    if (name === 'W1') result = '승';
    if (name === 'W2') result = '패';
    if (name === 'X') result = '무';
    if (name === 'Over') result = '오버';
    if (name === 'Under') result = '언더';
    if (name === '1X') result = '홈팀 또는 무승부';
    if (name === '12') result = '홈팀 또는 원정팀';
    if (name === 'X2') result = '무승부 또는 원정팀';
    if (name === 'Odd') result = '홀';
    if (name === 'Even') result = '짝';
    if (name === 'No Goal') result = '노골';
    if (name === 'AnyOther') return '기타';
    return result;
})

Vue.filter('betsName2',(betting) => {
    if (betting.betting_type === '라이브'){
        if (betting.betting_game_markets_name_kr === '핸디캡'){
            if (betting.betting_side === 'home'){
                let name =  `${betting.betting_game_home_name_kr}`;
                if (betting.betting_line && betting.betting_line !== 'null') name += ` (${betting.betting_line})`
                return name;
            }
            if (betting.betting_side === 'away'){
                let name =  `${betting.betting_game_away_name_kr}`;
                if (betting.betting_line && betting.betting_line !== 'null') name += ` (${Number(betting.betting_line) * -1})`
                return name;
            }
        } else if (betting.betting_game_markets_name_kr === '승패'){
            if (betting.betting_side === 'home'){
                let name =  `${betting.betting_game_home_name_kr}`;
                return name;
            }
            if (betting.betting_side === 'away'){
                let name =  `${betting.betting_game_away_name_kr}`;
                return name;
            }
        } else {
            if (betting.betting_side === 'home'){
                let name =  `오버`;
                if (betting.betting_line && betting.betting_line !== 'null') name += ` (${betting.betting_line})`
                return name;
            }
            if (betting.betting_side === 'away'){
                let name =  `언더`;
                if (betting.betting_line && betting.betting_line !== 'null') name += ` (${betting.betting_line})`
                return name;
            }
        }


    }
    if (betting.betting_game_markets_name_kr?.indexOf('정확한 골수') >= 0) {
        let name = betting.betting_bet_name
        return name;
    }

    if (betting.betting_bet_name=== '1' || betting.betting_bet_name=== 'Home') {
        let name =  `${betting.betting_game_home_name_kr || betting.betting_game_home_name_en}`;
        if (betting.betting_line) {
            if (betting.betting_line.split(' ')){
                name += ` (${betting.betting_line.split(' ')[0]})`
            } else {
                name += ` (${betting.betting_line})`
            }
        }
        return name;
    }
    if (betting.betting_bet_name === 'X') return '무';
    if (betting.betting_bet_name === '2' || betting.betting_bet_name=== 'Away') {
        let name = `${betting.betting_game_away_name_kr || betting.betting_game_away_name_en}`
        if (betting.betting_line) {
            if (betting.betting_line.split(' ')){
                name += ` (${betting.betting_line.split(' ')[0]})`
            } else {
                name += ` (${betting.betting_line})`
            }
        }
        return name;
    }
    if (betting.betting_bet_name=== 'W1') return `${betting.betting_game_home_name_kr || betting.betting_game_home_name_en}`
    if (betting.betting_bet_name=== 'W2') return `${betting.betting_game_away_name_kr || betting.betting_game_away_name_en}`
    if (betting.betting_bet_name === 'Over') return `오버 ${betting.betting_line}`;
    if (betting.betting_bet_name === 'Under') return `언더 ${betting.betting_line}`;
    if (betting.betting_bet_name === '1X') return `${betting.betting_game_home_name_kr || betting.betting_game_home_name_en} 또는 무승부`;
    if (betting.betting_bet_name === '12') return `${betting.betting_game_home_name_kr || betting.betting_game_home_name_en} 또는 ${betting.betting_game_away_name_kr || betting.betting_game_away_name_en}`;
    if (betting.betting_bet_name === 'X2') return `무승부 또는 ${betting.betting_game_away_name_kr || betting.betting_game_away_name_en}`;
    if (betting.betting_bet_name === 'Yes' && betting.betting_game_markets_name_kr === '1이닝 득무득') return '득점';
    if (betting.betting_bet_name === 'No' && betting.betting_game_markets_name_kr === '1이닝 득무득') return '무득점';

    if (betting.betting_bet_name === 'Odd') return '홀';
    if (betting.betting_bet_name === 'Even') return '짝';
    if (betting.betting_bet_name === 'AnyOther') return '기타';
    if (betting.betting_bet_name?.indexOf('Team1') >= 0) return  betting.betting_bet_name.replace('Team1', `${betting.betting_game_home_name_kr || betting.betting_game_home_name_en}`);
    if (betting.betting_bet_name?.indexOf('Team2') >= 0) return  betting.betting_bet_name.replace('Team2', `${betting.betting_game_away_name_kr || betting.betting_game_away_name_en}`);

    if (betting.betting_bet_name === 'NoGoal') return '노골';

    if (betting.betting_bet_name?.indexOf('W1andOver') >= 0) {
        let name = betting.betting_bet_name.replace('W1andOver', `${ betting.betting_game_home_name_kr || betting.betting_game_home_name_en } & 오버 ${betting.betting_line || ''}`)
        return name;
    }
    if (betting.betting_bet_name?.indexOf('W2andOver') >= 0) {
        let name = betting.betting_bet_name.replace('W2andOver', `${ betting.betting_game_away_name_kr || betting.betting_game_away_name_en } & 오버 ${betting.betting_line || ''}`);
        return name;
    }
    if (betting.betting_bet_name?.indexOf('1 And Over') >= 0) {
        let name = betting.betting_bet_name.replace('1 And Over', `${ betting.betting_game_home_name_kr || betting.betting_game_home_name_en } & 오버 ${betting.betting_line || ''}`)
        return name;
    }
    if (betting.betting_bet_name?.indexOf('1 And Under') >= 0) {
        let name = betting.betting_bet_name.replace('1 And Under', `${ betting.betting_game_home_name_kr || betting.betting_game_home_name_en } & 언더 ${betting.betting_line || ''}`);
        return name;
    }

    if (betting.betting_bet_name?.indexOf('2 And Over') >= 0) {
        let name = betting.betting_bet_name.replace('2 And Over', `${ betting.betting_game_away_name_kr || betting.betting_game_away_name_en } & 오버 ${betting.betting_line || ''}`)
        return name;
    }
    if (betting.betting_bet_name?.indexOf('2 And Under') >= 0) {
        let name = betting.betting_bet_name.replace('2 And Under', `${ betting.betting_game_away_name_kr || betting.betting_game_away_name_en } & 언더 ${betting.betting_line || ''}`);
        return name;
    }
    if (betting.betting_bet_name?.indexOf('X And Over') >= 0) {
        let name = betting.betting_bet_name.replace('X And Over', `무 & 오버 ${betting.betting_line || ''}`)
        return name;
    }
    if (betting.betting_bet_name?.indexOf('X And Under') >= 0) {
        let name = betting.betting_bet_name.replace('X And Under', `무 & 언더 ${betting.betting_line || ''}`);
        return name;
    }
    if (betting.betting_bet_name?.indexOf('12andOver') >= 0) {
        let name = betting.betting_bet_name.replace('12andOver', '12 & 오버')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('W1andUnder') >= 0) {
        let name = betting.betting_bet_name.replace('W1andUnder', `${betting.betting_game_home_name_kr || betting.betting_game_home_name_en} & 언더 ${betting.betting_line || ''}`)
        return name;
    }
    if (betting.betting_bet_name?.indexOf('W2andUnder') >= 0) {
        let name = betting.betting_bet_name.replace('W2andUnder', `${betting.betting_game_away_name_kr || betting.betting_game_away_name_en} & 언더 ${betting.betting_line || ''}`)
        return name;
    }
    if (betting.betting_bet_name?.indexOf('12andUnder') >= 0) {
        let name = betting.betting_bet_name.replace('12andUnder', '12 & 언더')
        return name;
    }

    if (betting.betting_bet_name?.indexOf('1XandOver') >= 0) {
        let name = betting.betting_bet_name.replace('1XandOver', '1X & 오버')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('XandOver') >= 0) {
        let name = betting.betting_bet_name.replace('XandOver', '무 & 오버')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('X2andOver') >= 0) {
        let name = betting.betting_bet_name.replace('X2andOver', 'X2 & 오버')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('1XandUnder') >= 0) {
        let name = betting.betting_bet_name.replace('1XandUnder', '1X & 언더')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('XAndUnder') >= 0) {
        let name = betting.betting_bet_name.replace('XAndUnder', '무 & 언더')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('X2andUnder') >= 0) {
        let name = betting.betting_bet_name.replace('X2andUnder', 'X2 & 언더')
        return name;
    }
    if (betting.betting_bet_name?.indexOf('ormore') >= 0) {
        let name = betting.betting_bet_name.replace('ormore', '골 또는 그이상')
        return name;
    }
    //여기서부터
    if (betting.betting_bet_name?.indexOf('12 & Under') >= 0) {
        let name = betting.betting_bet_name.replace('12 & Under', `12 & 언더 ${betting.betting_line || ''}`)
        return name;
    }

    if (betting.betting_bet_name?.indexOf('12 & Over') >= 0) {
        let name = betting.betting_bet_name.replace('12 & Over', `12 & 오버 ${betting.betting_line || ''}`)
        return name;
    }
    
    if (betting.betting_bet_name?.indexOf('1X & Under') >= 0) {
        let name = betting.betting_bet_name.replace('1X & Under', `1X & 언더 ${betting.betting_line || ''}`)
        return name;
    }
    
    if (betting.betting_bet_name?.indexOf('1X & Over') >= 0) {
        let name = betting.betting_bet_name.replace('1X & Over', `1X & 오버 ${betting.betting_line || ''}`)
        return name;
    }

    if (betting.betting_bet_name?.indexOf('X2 & Under') >= 0) {
        let name = betting.betting_bet_name.replace('X2 & Under', `X2 & 언더 ${betting.betting_line || ''}`)
        return name;
    }
    if (betting.betting_bet_name?.indexOf('X2 & Over') >= 0) {
        let name = betting.betting_bet_name.replace('X2 & Over', `X2 & 오버 ${betting.betting_line || ''}`)
        return name;
    }
    return betting.betting_bet_name
})


Vue.filter('cartBetsName',(item) => {
    if (item.betsName=== '1') {
        let name =  `${item.homeNameKr || item.homeNameEn}`;
        if (item.line && item.line !== 'null') {
            name += ` (${item.line.replace(' (0-0)', '')})`
        }
        return name;
    }
    if (item.betsName === '2') {
        let name = `${item.awayNameKr || item.awayNameEn}`
        if (item.line && item.line !== 'null') {
            name += ` (${item.line.replace(' (0-0)', '')})`
        }
        return name;
    }
    if (item.betsName === '2') return '패';
    if (item.betsName === 'W1') return '승';
    if (item.betsName === 'W2') return '패'
    if (item.betsName=== 'Home') {
        let name =  `${item.homeNameKr || item.homeNameEn}`;
        if (item.line && item.line !== 'null') {
            name += ` (${item.line})`
        }
        return name;
    }
    if (item.betsName === 'X') return '무';
    if (item.betsName === 'Away') {
        let name = `${item.awayNameKr || item.awayNameEn}`
        if (item.line && item.line !== 'null') {
            name += ` (${item.line})`
        }
        return name;
    }
    if (item.betsName === 'Over') return `오버 ${item.line}`;
    if (item.betsName === 'Under') return `언더 ${item.line}`;
    if (item.betsName === '1X') return '홈승 또는 무승부';
    if (item.betsName === '12') return '홈승 또는 원정승';
    if (item.betsName === 'X2') return '무승부 또는 원정승';
    if (item.betsName === 'Odd') return '홀';
    if (item.betsName === 'Even') return '짝';
    if (item.betsName === 'Yes' && item.marketNameKr === '1이닝 득무득') return '득점';
    if (item.betsName === 'No' && item.marketNameKr === '1이닝 득무득') return '무득점';
    if (item.betsName === 'Yes') return '예';
    if (item.betsName === 'No') return '아니오';
    if (item.betsName === 'Team1') return  `${ item.homeNameKr || item.homeNameEn }`;
    if (item.betsName === 'Team2') return  `${ item.homeNameKr || item.homeNameEn }`;
    if (item.betsName === 'AnyOther') return '기타';

    if (item.betsName === 'NoGoal') return '노골';

    if (item.betsName?.indexOf('W1andOver') >= 0) {
        let name = item.betsName.replace('W1andOver', `${ item.homeNameKr || item.homeNameEn } & 오버 ${item.line || ''}`)
        return name;
    }
    if (item.betsName?.indexOf('W2andOver') >= 0) {
        let name = item.betsName.replace('W2andOver', `${ item.awayNameKr || item.awayNameEn } & 오버 ${item.line || ''}`);
        return name;
    }
    if (item.betsName?.indexOf('12andOver') >= 0) {
        let name = item.betsName.replace('12andOver', '12 & 오버')
        return name;
    }
    if (item.betsName?.indexOf('W1andUnder') >= 0) {
        let name = item.betsName.replace('W1andUnder', `${item.homeNameKr || item.homeNameEn} & 언더 ${item.line || ''}`)
        return name;
    }
    if (item.betsName?.indexOf('W2andUnder') >= 0) {
        let name = item.betsName.replace('W2andUnder', `${item.awayNameKr || item.awayNameEn} & 언더 ${item.line || ''}`)
        return name;
    }
    if (item.betsName?.indexOf('12andUnder') >= 0) {
        let name = item.betsName.replace('12andUnder', '12 & 언더')
        return name;
    }

    if (item.betsName?.indexOf('1XandOver') >= 0) {
        let name = item.betsName.replace('1XandOver', '1X & 오버')
        return name;
    }
    if (item.betsName?.indexOf('XandOver') >= 0) {
        let name = item.betsName.replace('XandOver', '무 & 오버')
        return name;
    }
    if (item.betsName?.indexOf('X2andOver') >= 0) {
        let name = item.betsName.replace('X2andOver', 'X2 & 오버')
        return name;
    }
    if (item.betsName?.indexOf('1XandUnder') >= 0) {
        let name = item.betsName.replace('1XandUnder', '1X & 언더')
        return name;
    }
    if (item.betsName?.indexOf('XAndUnder') >= 0) {
        let name = item.betsName.replace('XAndUnder', '무 & 언더')
        return name;
    }
    if (item.betsName?.indexOf('X2andUnder') >= 0) {
        let name = item.betsName.replace('X2andUnder', 'X2 & 언더')
        return name;
    }
    if (item.betsName?.indexOf('ormore') >= 0) {
        let name = item.betsName.replace('ormore', '골 또는 그이상')
        return name;
    }
    return item.betsName;
})

Vue.filter('winBetsName',(name) => {
    let result = '적특';
    if (name === '1') result = '승';
    if (name === '2') result = '패';
    if (name === 'W1') result = '승';
    if (name === 'W2') result = '패';
    if (name === 'X') result = '무';
    if (name === 'Over') result = '오버';
    if (name === 'Under') result = '언더';
    if (name === '1X') result = '홈팀 또는 무승부';
    if (name === '12') result = '홈팀 또는 원정팀';
    if (name === 'X2') result = '무승부 또는 원정팀';
    return result;
})


Vue.filter('randomNum',() => {
    const ranNum = Math.floor(Math.random() * 999999 + 1);
    return ranNum
})

