<template>
  <div class="form-container">
    <div class="form-group">
      <label for="category">카테고리</label>
      <select id="category" v-model="payload.category" style="padding: -10px">
        <option
          v-for="option in categoryOptions"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="title">제목</label>
      <input
        type="text"
        id="title"
        placeholder="제목입력"
        v-model="payload.title"
      />
    </div>
    <div class="form-group">
      <label for="content">내용</label>
      <textarea
        id="content"
        placeholder="내용입력"
        v-model="payload.content"
      ></textarea>
    </div>
    <div class="form-group betting-list">
      <table
        v-if="payload.bettings?.length > 0"
        id="tableAll1"
        cellspacing="0"
        cellpadding="0"
        width="100%"
        class="dataTable no-footer desktop"
        role="grid"
        style="width: 100%; display: block;"
      >
        <thead style="width: 1920px;">
          <tr role="row" style="width: 100%;">
            <th class="sorting" style="width: 12.5%">베팅 NO</th>
            <th
              class="sorting_desc"
              style="width: 12.5%"
              aria-sort="descending"
            >
              베팅 일시
            </th>
            <th class="sorting_asc" style="width: 12.5%">베팅 종목</th>
            <th class="sorting" style="width: 12.5%">베팅 결과</th>
            <th class="sorting" style="width: 12.5%">배팅 금액</th>
            <th class="sorting" style="width: 12.5%">배당율</th>
            <th class="sorting" style="width: 12.5%">예상 적중 금액</th>
            <th class="sorting" style="width: 12.5%">폴더</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row, index) in payload.bettings">
            <tr
              id="tableall1_2802777"
              role="row"
              class="odd"
              :key="'bettingTitle' + index"
            >
              <td @click="toggle(index)">
                {{ row[0].betting_betcode }} <br />
                {{ row[0].betting_type }}
              </td>
              <td @click="toggle(index)" class="sorting_1">
                {{
                  row[0].betting_regdatetime
                    | formatDate("YYYY-MM-DD HH:mm:ss")
                }}
              </td>
              <td @click="toggle(index)">
                {{
                  row[0].betting_game_sports_name_kr ||
                  row[0].betting_game_sports_name_en
                }}
                {{ row.length > 1 ? "외" : "" }}
              </td>
              <td
                @click="toggle(index)"
                v-if="row[0].betting_status.indexOf('취소') < 0"
              >
                <span
                  :class="{
                    'text-yellow':
                      row[0].betting_total_result === 'exception',
                    'text-red': row[0].betting_total_result === 'miss',
                    'text-green': row[0].betting_total_result === 'hit',
                    'text-gray': row[0].betting_total_result === 'wait',
                  }"
                >
                  <template v-if="row[0].betting_total_result === 'hit'">
                    {{ row[0].betting_payed === "y" ? "당첨" : "지급대기" }}
                  </template>
                  <template v-else>
                    {{ row[0].betting_total_result | bettingStatus }}
                  </template>
                </span>
              </td>
              <td v-else>취소</td>
              <td @click="toggle(index)">
                <span
                  :class="{
                    'text-yellow':
                      row[0].betting_total_result === 'exception',
                    'text-red': row[0].betting_total_result === 'miss',
                    'text-green': row[0].betting_total_result === 'hit',
                    'text-gray': row[0].betting_total_result === 'wait',
                  }"
                  >{{ row[0].betting_bet_amount | makeComma }}</span
                >
              </td>
              <td @click="toggle(index)">
                <span
                  :class="{
                    'text-yellow':
                      row[0].betting_total_result === 'exception',
                    'text-red': row[0].betting_total_result === 'miss',
                    'text-green': row[0].betting_total_result === 'hit',
                    'text-gray': row[0].betting_total_result === 'wait',
                  }"
                  >{{ Number(row[0].betting_total_odds).toFixed(2) }}
                </span>
              </td>
              <td @click="toggle(index)">
                <span
                  :class="{
                    'text-yellow':
                      row[0].betting_total_result === 'exception',
                    'text-red': row[0].betting_total_result === 'miss',
                    'text-green': row[0].betting_total_result === 'hit',
                    'text-gray': row[0].betting_total_result === 'wait',
                  }"
                  >{{ row[0].betting_expected_prize | makeComma }}</span
                >
              </td>
              <td @click="toggle(index)">
                {{ row.length > 1 ? `${row.length}폴더` : "단폴더" }}
              </td>
            </tr>
            <transition name="fade" :key="'bettingDetail' + index">
              <tr v-if="opened.includes(index)">
                <td colspan="9">
                  <div class="sub_table">
                    <table
                      id="sub_tableall1_2802725"
                      class="panel-body bet_dd_p"
                      cellpadding="0"
                      cellspacing="0"
                      :class="{
                        acc_list_table_in_01:
                          row[0].betting_total_result === 'exception',
                        acc_list_table_in_02:
                          row[0].betting_total_result === 'miss',
                        acc_list_table_in_04:
                          row[0].betting_total_result === 'hit',
                        acc_list_table_in_03:
                          row[0].betting_total_result === 'wait',
                      }"
                    >
                      <tbody>
                        <tr>
                          <td class="acc_list_table_in_t" width="11%">
                            경기일시
                          </td>
                          <td class="acc_list_table_in_t" width="8%">종목</td>
                          <td class="acc_list_table_in_t" width="15%">
                            리그
                          </td>
                          <td class="acc_list_table_in_t" width="18%">팀</td>
                          <td class="acc_list_table_in_t" width="10%">
                            타입
                          </td>
                          <td class="acc_list_table_in_t" width="14%">
                            배팅
                          </td>
                          <td class="acc_list_table_in_t" width="6%">
                            배당율
                          </td>
                          <td class="acc_list_table_in_t" width="6%">결과</td>
                        </tr>
                        <tr
                          v-for="(row2, index2) in row"
                          :key="'bets' + row.bets_id + index2"
                        >
                          <td class="bet_time">
                            {{
                              row2.betting_game_starttime
                                | formatDate("YYYY-MM-DD HH:mm:ss")
                            }}
                          </td>
                          <td class="bet_event">
                            {{
                              row2.betting_game_sports_name_kr ||
                              row2.betting_game_leagues_name_en
                            }}
                          </td>
                          <td class="bet_name">
                            {{
                              row2.betting_game_leagues_name_kr ||
                              row2.betting_game_leagues_name_en
                            }}
                          </td>
                          <td class="bet_name">
                            {{
                              row2.betting_game_home_name_kr ||
                              row2.betting_game_home_name_en
                            }}
                            -
                            {{
                              row2.betting_game_away_name_kr ||
                              row2.betting_game_away_name_en
                            }}
                          </td>
                          <td class="bet_type">
                            {{
                              row2.betting_game_markets_name_kr ||
                              row2.betting_game_markets_name_en
                            }}
                            <span v-if="row2.betting_round">
                              ({{ row2.betting_round }})
                            </span>
                          </td>
                          <td class="bet_type">
                            {{ row2 | betsName2 }}
                          </td>
                          <td class="bet_type">
                            {{
                              row.length === 1
                                ? (
                                    Number(row[0].betting_odds) +
                                    Number(row[0].betting_total_odds_penalty)
                                  ).toFixed(2)
                                : row2.betting_odds
                            }}
                          </td>
                          <td
                            class="beting_in_btn"
                            v-if="row2.betting_status.indexOf('취소') < 0"
                          >
                            <span
                              :class="{
                                'text-yellow':
                                  row2.betting_result === 'exception',
                                'text-red': row2.betting_result === 'miss',
                                'text-green': row2.betting_result === 'hit',
                                'text-gray': row2.betting_result === 'wait',
                              }"
                            >
                              {{ row2.betting_result | bettingStatus }}</span
                            >
                          </td>
                          <td v-else>취소</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </transition>
          </template>
        </tbody>
      </table>
      <div class="m-game3 mt10 mobile" v-if="payload.bettings?.length > 0">
        <div class="m_betting_div" v-for="bet in payload.bettings" :key="'betting'+ bet[0].betting_seq">
          <div
              class="total_betting_div"
              :class="{
              'bg-y': bet[0].betting_total_result === 'exception',
              'bg-r': bet[0].betting_total_result === 'miss',
              'bg-g': bet[0].betting_total_result === 'hit',
              'bg-gr': bet[0].betting_total_result === 'wait',
            }"
          >
            <table border="0" width="100%">
              <tr>
                <td class="text-gray">
                  일시 :
                  <span>{{
                      bet[0].betting_regdatetime | formatDate("YY-MM-DD")
                    }}</span>
                </td>
                <td width="20%" rowspan="6">
                </td>
              </tr>
              <tr>
                <td style="color: rgb(153, 153, 153)">
                  베팅아이디 :
                  <span
                  >{{ bet[0].betting_betcode }} /
              {{ bet[0].betting_type }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>
                  폴더
                  <span class="orange--text">{{ payload.bettings.length }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  베팅금액
                  <span class="orange--text">{{
                      bet[0].betting_bet_amount | makeComma
                    }}</span
                  >원
                </td>
              </tr>
              <tr>
                <td>
                  베당율
                  <span
                      class="orange--text"
                      v-if="bet[0].betting_total_result !== 'hit'"
                  >
              {{
                      (
                          Number(bet[0].betting_total_odds) +
                          Number(bet[0].betting_total_odds_penalty)
                      ).toFixed(2)
                    }}
            </span>
                  <span class="orange--text" v-else>{{
                      bet[0].betting_payed_odds | makeComma
                    }}</span
                  >배
                </td>
              </tr>
              <tr>
                <td>
                  (예상)적중금액
                  <span
                      class="orange--text"
                      v-if="bet[0].betting_total_result !== 'hit'"
                  >{{ bet[0].betting_expected_prize | makeComma }}</span
                  >
                  <span class="orange--text" v-else>{{
                      bet[0].betting_payed_amount | makeComma
                    }}</span
                  >원
                </td>
              </tr>
            </table>
          </div>
          <transition name="fade">
            <div style="" >
              <div
                  class="sub_betting_div"
                  :class="{
                      'border-y': row2.betting_result === 'exception',
                      'border-r': row2.betting_result === 'miss',
                      'border-g': row2.betting_result === 'hit',
                      'border-gr': row2.betting_result === 'wait',
                    }"
                  v-for="(row2, index2) in bet"
                  :key="'bettingDetail' + index2 + row2.betting_folder"
              >
                <table
                    border="0"
                    width="100%"
                    style="color: white; font-size: 14px; padding-left: 10px"
                >
                  <tr class="text-gray">
                    <td colspan="2" class="pt-2 text-gray">
                      {{
                        row2.betting_game_starttime | formatDate("YY-MM-DD HH:mm")
                      }}
                      |
                      {{
                        row2.betting_game_leagues_name_kr ||
                        row2.betting_game_leagues_name_en
                      }}
                    </td>
                  </tr>
                  <tr class="text-gray">
                    <td class="text-gray">게임아이디: {{ row2.betting_game }}</td>
                    <td
                        class="text-gray text-right pr-3"
                        style="text-align: right; line-height: 1.4"
                    >
                      {{
                        bet.length === 1
                            ? (
                                Number(bet[0].betting_odds) +
                                Number(bet[0].betting_total_odds_penalty)
                            ).toFixed(2)
                            : row2.betting_odds
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td style="color: white">
                      {{
                        row2.betting_game_home_name_kr ||
                        row2.betting_game_home_name_en
                      }}
                      <span class="grey--text text-gray"> vs </span>
                      {{
                        row2.betting_game_away_name_kr ||
                        row2.betting_game_away_name_en
                      }}
                    </td>
                    <td
                        class="text-right pr-3"
                        v-if="row2.betting_status.indexOf('취소') < 0"
                        style="text-align: right; line-height: 1.4; color: white"
                    >
                      {{ row2.betting_result | bettingStatus }}
                    </td>
                    <td
                        class="text-right pr-3"
                        style="text-align: right; line-height: 1.4; color: white"
                        v-else
                    >
                      취소
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="pb-2" style="color: white">
                <span style="color: deepskyblue">{{
                    row2.betting_game_markets_name_kr ||
                    row2.betting_game_markets_name_en
                  }}</span>
                      <span v-if="row2.betting_round"
                      >( {{ row2.betting_round }} )</span
                      >
                      / {{ row2 | betsName2 }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </transition>
        </div>
        <!--            <div class="title" style="margin: 5px 0px;">-->
        <!--              <div class="left">-->
        <!--                <div class="subj">베팅결과 : <strong class="yellow2">{{ bet[0].betting_total_result | bettingStatus }}</strong></div>-->
        <!--              </div>-->
        <!--              <div class="right">{{ bet[0].betting_regdatetime | formatDate('MM-DD HH:mm') }}</div>-->
        <!--            </div>-->
        <!--            <template v-for="(row2, index2) in payload.betting" >-->
        <!--              <div :key="'bets'+row2.bets_id+index2">-->
        <!--                <div class="title type2" style="margin: 5px 0px;">-->
        <!--                  <div class="left">-->
        <!--                    <div class="subj">{{ row2.betting_game_leagues_name_kr || row2.betting_game_leagues_name_en }} &nbsp;&nbsp;[{{ row2.betting_score_home }}:{{ row2.betting_score_away }}]</div>-->
        <!--                  </div>-->
        <!--                  <div class="right">{{ row2.betting_game_starttime | formatDate('MM-DD HH:mm') }}</div>-->
        <!--                </div>-->
        <!--                <div class="game-item">-->
        <!--                  <dl :class="{'active': row2.betting_side === 'home'}">-->
        <!--                    <dt>{{ row2.betting_game_home_name_kr }}</dt>-->
        <!--                    <dd><span>{{ row2.betting_odds1 }}</span></dd>-->
        <!--                  </dl>-->
        <!--                  <div class="vs" :class="{'active': row2.betting_side === 'draw'}" v-if="row2.betting_odds2">{{ row2.betting_odds2 }}</div>-->
        <!--                  <div class="vs" v-else-if="row2.betting_line">{{ row2.betting_line }}</div>-->
        <!--                  <div class="vs" v-else>VS</div>-->
        <!--                  <dl :class="{'active': row2.betting_side === 'away'}">-->
        <!--                    <dt>{{ row2.betting_game_away_name_kr }}</dt>-->
        <!--                    <dd><span>{{ row2.betting_odds3 }}</span></dd>-->
        <!--                  </dl>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </template>-->
        <!--            <div class="bet-result">-->
        <!--              <dl>-->
        <!--                <dt>베팅금액</dt>-->
        <!--                <dd>{{ bet[0].betting_bet_amount | makeComma }}원</dd>-->
        <!--              </dl>-->
        <!--              <dl>-->
        <!--                <dt>배당률</dt>-->
        <!--                <dd>{{ (Number(bet[0].betting_total_odds) + Number(bet[0].betting_total_odds_penalty)).toFixed(2) }}</dd>-->
        <!--              </dl>-->
        <!--              <dl>-->
        <!--                <dt>당첨예상금</dt>-->
        <!--                <dd>{{ bet[0].betting_expected_prize | makeComma }}원</dd>-->
        <!--              </dl>-->
        <!--              <dl>-->
        <!--                <dt>당첨금</dt>-->
        <!--                <dd>{{ bet[0].betting_payed_amount | makeComma }}원</dd>-->
        <!--              </dl>-->
        <!--            </div>-->
        <div class="btns">
          <a @click="deleteBetting" class="b1">첨부삭제</a>
        </div>

      </div>
    </div>
    <div class="form-group lotto-list" v-if="payload.lotto">
      <div class="table-container ">
        <div class="flex-item">
          <table>
            <thead>
                <tr>
                    <th>회차</th>
                    <th>응모번호</th>
                    <th>당첨번호</th>
                    <th>지급시간</th>
                    <th>응모시간</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ payload.lotto.lottoIndex }}</td>
                    <td class="bet-res">
                      <span
                        v-for="(row2, index2) in payload.lotto.number.split(',')"
                        :key="'lottoListNumber' + index +index2"
                        style="margin-right: 5px;"
                        :class="{
                          'bg-1' : Number(row2) <= 10 && Number(row2) > 0,
                          'bg-10' : Number(row2) <= 20 && Number(row2) > 10,
                          'bg-20' : Number(row2) <= 30 && Number(row2) > 20,
                          'bg-30' : Number(row2) <= 40 && Number(row2) > 30,
                          'bg-40' : Number(row2) > 40,
                        }"
                      >
                        {{ row2 }}
                      </span>
                    </td>
                    <td class="bet-res">
                      <span
                        v-for="(row2, index2) in  payload.lotto.hitNumber?.split(',')"
                        :key="'lottoListNumber' + index +index2"
                        style="margin-right: 5px;"
                        :class="{
                          'bg-1' : Number(row2) <= 10 && Number(row2) > 0,
                          'bg-10' : Number(row2) <= 20 && Number(row2) > 10,
                          'bg-20' : Number(row2) <= 30 && Number(row2) > 20,
                          'bg-30' : Number(row2) <= 40 && Number(row2) > 30,
                          'bg-40' : Number(row2) > 40,
                        }"
                      >
                        {{ row2 }}
                      </span>
                    </td>
                    <td>{{  payload.lotto.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                    <td>{{  payload.lotto.updatedAt | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="betting">베팅내역첨부</label>
      <div class="form-actions" v-if="submitText !== '수정하기'">
        <button class="add-bet" @click="openList">베팅내역 추가</button>
        <button class="clear-bet" @click="deleteBetting">
          베팅내역 전부삭제
        </button>
      </div>

    </div>
    <div class="mobile">
      <transition name="fade">
        <MBettingAdd
          v-if="isView"
          :open-list="openList"
          :add-betting-list="addBetting"
        />
      </transition>
    </div>
    <div class="form-footer">
      <button class="submit" @click="handleSubmit">{{ submitText }}</button>
      <button class="cancel" @click="handleCancel">취소</button>
    </div>
    <div class="desktop">
      <transition name="fade">
        <BettingAdd
          v-if="isView"
          :open-list="openList"
          :add-betting-list="addBetting"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import BettingAdd from "@/components/Board/BettingAdd";
import MBettingAdd from "@/components/Board/MBettingAdd";

export default {
  name: "PostForm",
  components: { BettingAdd, MBettingAdd },
  props: {
    submitText: { type: String, required: true },
    payload: { type: Object, required: true },
  },
  data() {
    return {
      isView: false,
      isBettingChanged: false,
      opened: [],
      categoryOptions: [
        // { value: null, label: "선택" },
        { value: 1, label: "일반" },
        // { value: 2, label: "이벤트" },
        // { value: 3, label: "공지" },
      ],
    };
  },
  methods: {
    openList() {
      this.isView = !this.isView;
    },
    addBetting(betting) {
      // console.log('추가전 베팅', this.payload.bettings)
      console.log('추가할 베팅', betting)
      this.payload.bettings.push([...betting]);
      console.log('추가후 베팅', this.payload.bettings)
      
      this.isView = false;
      this.isBettingChanged = true;
    },
    deleteBetting() {
      console.log('현재베팅내역1', this.payload.bettings)
      this.payload.bettings = [];
      console.log('현재베팅내역2',this.payload.bettings)
      this.payload.isBettingChanged = true;
    },
    handleSubmit() {
      this.$emit("submit", { ...this.payload });
    },
    handleCancel() {
      this.$emit("cancel");
    },
    toggle: function (id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
  },
};
</script>

<style scoped>
@media all and (min-width: 600px) {
  .mobile {
    display: none !important;
  }
  .main {
    padding: 20px;
  }
  .betting-list {
    padding-left: 110px;
  }
  .main h1 {
    color: #b38ff5;
    text-align: center;
  }
  .form-container {
    background-color: #2b2b2b;
    padding: 20px;
    border-radius: 5px;
  }
  .form-group {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  .form-group label {
    width: 100px;
    margin-right: 10px;
  }
  .form-group input,
  .form-group textarea {
    flex: 1;
    padding: 10px;
    border: 1px solid #444444;
    border-radius: 5px;
    background-color: #1c1c1c;
    color: #ffffff;
  }
  .form-group select {
    flex: 1;
    border: 1px solid #444444;
    border-radius: 5px;
    background-color: #1c1c1c;
    color: #ffffff;
  }
  .form-group textarea {
    height: 200px;
  }
  .form-actions {
    display: block;
    justify-content: space-between;
  }
  .form-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .form-actions .add-bet {
    background-color: #b38ff5;
    color: #ffffff;
  }
  .form-actions .clear-bet {
    background-color: #8b0000;
    color: #ffffff;
  }
  .form-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .form-footer button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  .form-footer .submit {
    background-color: #b38ff5;
    color: #ffffff;
  }
  .form-footer .cancel {
    background-color: #444444;
    color: #ffffff;
  }
}
@media all and (max-width: 600px) {
  .desktop {
    display: none !important;
  }
  .form-container {
    padding: 10px;
  }
  .form-group {
    margin-bottom: 20px;
  }
  .form-group label {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 18px;
    margin-right: 10px;
  }
  .form-group input,
  .form-group textarea {
    width: calc(100% - 20px);
    padding: 10px;
    background-color: #2c2c2c;
    border: 1px solid #444444;
    color: #ffffff;
    font-size: 16px;
  }
  .form-group select {
    width: calc(100% - 20px);
    background-color: #2c2c2c;
    border: 1px solid #444444;
    color: #ffffff;
    font-size: 16px;
  }
  .form-group textarea {
    height: 200px;
  }
  .form-actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .form-actions button {
    background-color: #b68aff;
    border: none;
    color: #ffffff;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 10px;
    flex: 1 1 48%;
    box-sizing: border-box;
  }
  .form-actions button:last-child {
    background-color: #a00000;
  }
  .form-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .form-footer button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  .form-footer .submit {
    background-color: #b38ff5;
    color: #ffffff;
  }
  .form-footer .cancel {
    background-color: #444444;
    color: #ffffff;
  }

  @media (max-width: 600px) {
    .header .menu .info {
      display: none;
    }
    .header .menu .buttons button {
      padding: 5px 10px;
      margin-left: 5px;
    }
    .form-actions button {
      flex: 1 1 45%;
      gap: 3px;
    }
    .form-group label,
    .form-group select {
      display: block;
      width: 100%;
    }
    .form-group select {
      width: 100%;
    }
  }
}

.table-container {
    margin-top: 20px;
}
.table-container h2 {
    color: #b3b3ff;
}
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}
table th, table td {
    border: 1px solid #333;
    padding: 10px;
    text-align: center;
}
table th {
    background-color: #333;
    color: #fff;
}
.form-group.betting-list {
  width: 100%;
  display: block;
}
.form-group.lotto-list {
  width: 100%;
  display: block;
}
</style>
