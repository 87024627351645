<template>
  <div class="contRight"  style="position: sticky;">
    <div class="timer">
      <img src="@/assets/img/ico/ico_time.png" />
      <span class="t01">TIME</span>
      <span class="t02">{{ this.time.hours }}:{{ this.time.minutes }}:{{ this.time.seconds }}</span>
    </div>

    <div class="cartBox">
      <div class="box1">
        <p>배당변경 자동적용</p>
        <div>
          <label><input type="checkbox" class="switch" checked><span><em></em></span></label>
        </div>
      </div>

      <div class="cartHeader">
        <p class="subj">BETSLIP</p>
        <div class="chkBox">
          <label>카트고정</label>
          <input type="checkbox" id="cartChk" />
          <a href="javascript:void(0)"><img src="@/assets/img/ico/ico_delete.png" /></a>
        </div>
      </div>
      <ul class="btList01">
        <li>
          <span class="t01">보유머니</span>
          <span class="t02">{{ balance | makeComma }} 원</span>
        </li>
        <li>
          <span class="t01">최소베팅금액</span>
          <span class="t02">{{ minAmount | makeComma }} 원</span>
        </li>
        <li>
          <span class="t01">최대베팅금액</span>
          <span class="t02">{{ maxAmount | makeComma }} 원</span>
        </li>
      </ul>
      <ul class="btList02">
        <li>
          <div class="t01">배당률합계</div>
          <div class="t02 mr-5">
            <a @click="maxBet">MAX</a>
            <span class="ml5">{{ odds }}</span>
          </div>
        </li>
        <li>
          <div class="t01">베팅금액</div>
          <div class="t02"><input type="text" class="inp" v-model="amount"/></div>
        </li>
        <li>
          <div class="t01">예상배당금</div>
          <div class="t02">{{ winnings | makeComma }} 원</div>
        </li>
      </ul>

      <div class="btList03">
        <div class="article" v-for="(item, idx) in cartItem" :key="'cartItem'+idx">
          <dl>
            <dt>{{ item.homeNameKr ? item.homeNameKr : item.homeNameEn }}</dt>
            <dd>
              {{ item.betsName | cartBetType }}
              <a @click="deleteItemCart(item)">
                <img src="@/assets/img/ico/betting_close.png">
              </a>
            </dd>
          </dl>
          <dl>
            <dt>{{ item.awayNameKr ? item.awayNameKr : item.awayNameEn }}</dt>
            <dd><span>[{{ item.marketNameKr ? item.marketNameKr : item.marketNameEn }}]</span> {{ item.odds }}</dd>
          </dl>
        </div>
      </div>

      <div class="bettingBtnList">
        <a @click="addAmount(10000)">1만원</a>
        <a class="ml5" @click="addAmount(30000)">3만원</a>
        <a class="ml5" @click="addAmount(40000)">5만원</a>
        <a @click="addAmount(100000)">10만원</a>
        <a class="ml5" @click="addAmount(500000)">50만원</a>
        <a class="ml5" @click="resetCart">정정</a>
      </div>

      <a @click="processBet" class="btnBetting">베팅하기</a>

    </div>

  </div>
</template>

<script>
import * as momentTemp from 'moment';

export default {
  props: {
    type: String,
    timer: {
      type: Boolean,
      default: false
    },
    round: {
      type: Number,
      default: null
    },
  },
  name: "Cart",
  data: function(){
    return {
      // 베팅금액
      amount: 0,
      // 당첨금
      winnings: 0,
      // 베팅 최소금액
      minAmount: 5000,
      // 베팅 최대금액
      maxAmount: 1500000,
      // 당첨금 상한선
      limitWinnings: 4500000,
      // 현재 배당에서 최대 배팅금액,
      maxOddsAmount: 0,
      //현재시간
      interval: null,
      time: {
        hours: '00',
        minutes: '00',
        seconds: '00',
      },
      path: 'betting/process'
    }
  },
  computed:{
    odds: function(){
      if (this.type === 'minigame') return Number(this.$store.getters["cart/minigameCartTotalOdds"])
      if (this.type === 'lotus') return Number(this.$store.getters["cart/lotusCartTotalOdds"])
      if (this.type === 'special') return Number(this.$store.getters["cart/specialCartTotalOdds"])
      if (this.type === 'inplay') return Number(this.$store.getters["cart/inplayCartTotalOdds"])
      if (this.type === 'sports') return Number(this.$store.getters["cart/sportsCartTotalOdds"])
      return Number(this.$store.getters["cart/prematchCartTotalOdds"])
    },
    balance: function (){
      return Number(this.$store.getters["auth/cash"])
    },
    point: function (){
      return Number(this.$store.getters["auth/point"])
    },
    level: function (){
      return Number(this.$store.getters["auth/level"])
    },
    nickname: function (){
      return String(this.$store.getters["auth/nickname"])
    },
    cartItem: function(){
      return this.$store.state.cart[this.type]
    },
  },
  beforeDestroy: function() {
    // prevent memory leak
    clearInterval(this.interval)
  },
  created: function() {
    // 현재 배당에서 최대로 베팅가능한 금액 적용
    this.maxOddsAmount = Math.floor(this.limitWinnings / this.odds);
    this.interval = setInterval(() => {
      let now = new Date();
      now = momentTemp(now)
      this.time.hours= String(now.hours()).length === 1 ? `0${String(now.hours())}` : String(now.hours())
      this.time.minutes= String(now.minutes()).length === 1 ? `0${String(now.minutes())}` : String(now.minutes())
      this.time.seconds= String(now.seconds()).length === 1 ? `0${String(now.seconds())}` : String(now.seconds())
    }, 1000)
  },
  watch:{
    // 배당변경시 자동으로 최대 가능 베팅금액 변경
    odds: function (val){
      this.maxOddsAmount = Math.floor(this.limitWinnings / val);
      if (this.maxOddsAmount < this.amount) {
        this.amount = this.maxOddsAmount
      }
      this.calWinnings();
    },
    // 베팅금액 초과시 자동으로 최대 가능 베팅금액 변경
    amount: function (val){
      if (this.maxAmount < val) {
        this.maxBet()
        return alert('베팅금액은 최대베팅금액을 초과할 수 없습니다.')
      }
      if (this.balance < val) {
        this.amount = this.balance;
        return alert('베팅금액은 보유금액을 초과할 수 없습니다.')
      }
      if (this.maxOddsAmount < val) {
        this.maxBet()
        this.amount = this.maxOddsAmount
      }
      this.calWinnings();
    }
  },
  methods: {
    toggleAutoUpdate: function(){
      this.autoUpdate = !this.autoUpdate;
    },
    movePage: function(page){
      this.$router.push({ path: `/${page}`})
    },
    addAmount: function(amount){
      this.amount += Number(amount);
    },
    resetCart: function (){
      this.amount = 0;
    },
    maxBet: function(){
      // 보유금액 * 배당이 limitWinnings에 못미칠때
      // 보유금액 전부
      if (this.balance < this.maxOddsAmount){
        this.amount = this.balance;
      }
      // 보유금액 * 배당 limitWinnings에 넘을때
      // limitWinnings 근사치에 베팅
      // 베팅금액을 조정
      if (this.balance > this.maxOddsAmount){
        this.amount = this.maxOddsAmount;
      }
    },
    calWinnings: function (){
      this.winnings = Math.floor(this.amount * this.odds);
    },
    //카트에서 item 삭제
    deleteItemCart: function(obj){
      if(this.type === 'sports'){
        this.$store.dispatch('cart/subSportsCart',obj)
      }
      if(this.type === 'minigame'){
        this.$store.dispatch('cart/subMinigameCart',obj)
      }
    },
    processBet: function(){
      let msg = {};
      if (!this.isActiveFreeBet && this.amount < this.minAmount) return alert('최소 베팅금은 5,000원 입니다.');
      let confirmMsg = '';
      if(this.type === 'sports'){
        if(this.cartItem.length === 1){
          confirmMsg += '단폴 베팅시 0.02% 배당 하락이 발생합니다.\n';
        }
      }
      msg.bettings = this.$store.state.cart[this.type];

      confirmMsg += '베팅 하시겠습니까?'

      msg.amount = this.amount;
      msg.totalOdds = this.odds;
      msg.winnings = this.winnings;

      if(confirm(confirmMsg)){
        this.$store.dispatch('auth/post', {path: this.path, data: msg })
            .then(data => {
              const result = data.data;
              if (result.success) {
                if (this.type !== 'minigame') this.$router.push({ path: '/betResult'})
                this.$store.state.auth.user.cash = result.payload.member.members_cash;
                this.$store.state.auth.user.point = result.payload.member.members_point;
              }
            }).catch(err => {
              alert(err.response.data.error);
            });
        this.amount = 0;
        this.$store.state.cart[this.type] = [];
      }
    }
  },
}
</script>

<style scoped>

</style>
