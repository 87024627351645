<template>
  <div class="list-type1">
    <div class="item" v-for="(league, leagueIndex) in gameList" :key="'league' + leagueIndex">
      <div class="title" style="background-color: #262a2b">
        <img class="game-logo" v-if="league.lname.indexOf('LOL') > -1" src="@/assets/img/ico/esports/lol.png" />
        <img class="game-logo" v-else-if="league.lname.indexOf('SC') > -1" src="@/assets/img/ico/esports/sc.png" />
        <img class="game-logo" v-else-if="league.lname.indexOf('CS') > -1" src="@/assets/img/ico/esports/cs.png" />
        <img class="game-logo" v-else-if="league.lname.indexOf('철권') > -1" src="@/assets/img/ico/esports/tk.png" />
        <img class="game-logo" v-else-if="league.lname.indexOf('Valorant') > -1" src="@/assets/img/ico/esports/vr.png" />
        <img class="game-logo" v-else-if="league.lname.indexOf('e스포츠') > -1" src="@/assets/img/ico/esports/alarm.png" />
        <img class="game-logo" v-else-if="league.lname.indexOf('스트리트') > -1" src="@/assets/img/ico/esports/sf.png" />
        <!-- <img v-if="lists.find(e => e.leagueName === league).league" :src="lists.find(e => e.leagueName === league).league?.img" style="margin-left: 10px; max-width: 30px; height: auto;" alt=""> -->
        <span class="mr10" style="margin-right: 10px; margin-left: 10px">
          {{ league.lname }}
        </span>
      </div>
      <template v-for="(fixture, fixtureIndex) in league.fixtures">
        <template v-for="(game, gameIndex) in fixture.games">
          <template v-for="(market, marketIndex) in game.markets">
            <template v-for="(folder, folderIndex) in market.folders">
              <!-- {{ folder }} -->
              <div
                :class="{ before: !checkArray(fixture), after: checkArray(fixture) }"
                :key="'fixtureBox' + fixtureIndex + '-' + gameIndex + '-' + marketIndex + '-' + folderIndex"
              >
                <transition name="fade" :key="'fixtureTransition' + fixtureIndex">
                  <div class="box" :key="'koreanFolders' + fixtureIndex + -+gameIndex" v-show="(gameIndex === 0 && marketIndex === 0 && folderIndex === 0) || checkArray(fixture)">
                    <div class="date">
                      <p>
                        &nbsp;
                        <span style="color: white; font-size: 15px">
                          <strong>{{ folder.gamedate | formatDate1("YY-MM-DD") }}</strong>
                        </span>
                        <span class="time" style="color: #da2c36; margin-left: 3px; font-size: 15px">
                          <strong>{{ folder.gamedate | formatDate1("HH:mm") }}</strong>
                        </span>
                      </p>
                    </div>
                    <div class="type">
                      {{ folder.mc }}
                    </div>
                    <div class="bet-box">
                      <dl class="home" :class="{ active: checkCart(folder, 'home') }" @click="!isLocked(folder, 'oh') && addCart(folder, 'home')">
                        <dt>
                          {{ folder.hname }}
                        </dt>
                        <dd>
                          <template v-if="!isLocked(folder, 'oh')">
                            <span class="ib">
                              <img
                                v-if="market.mc === '핸디캡' && checkCart(folder, 'home')"
                                style="width: 8px; margin-top: -3px"
                                src="@/assets/img/ico/white/h.png"
                                alt=""
                              />
                              <img
                                v-if="market.mc === '핸디캡' && !checkCart(folder, 'home')"
                                style="width: 8px; margin-top: -3px"
                                src="@/assets/img/ico/red/h.png"
                                alt=""
                              />
                              <img
                                v-if="market.mc === '오버언더'"
                                style="width: 9px; height: 8px; margin-top: -3px"
                                src="@/assets/img/ico/m_ico_up.png"
                                alt=""
                              />
                            </span>
                            <span class="orange ib">{{
                              folder.oh
                            }}</span>
                          </template>
                          <span v-else class="ib">
                            <img style="width: 9px; margin-top: -3px" src="@/assets/img/ico/ico_secret.png" />
                          </span>
                        </dd>
                      </dl>
                      <div class="vs orange--text">
                        {{ folder.baseline === "1X2" ? "VS" : folder.baseline }}
                      </div>
                      <dl class="away" @click="!isLocked(folder, 'oa') && addCart(folder, 'away')" :class="{ active: checkCart(folder, 'away') }">
                        <dt>
                          {{ folder.aname }}
                        </dt>
                        <dd>
                          <template v-if="!isLocked(folder, 'oa')">
                            <span  class="orange ib">
                              {{ folder.oa }}
                            </span>
                            <span class="ib">
                              <img
                                v-if="market.mc === '오버언더'"
                                style="width: 9px; height: 8px; margin-top: -3px"
                                src="@/assets/img/ico/m_ico_down.png"
                                alt=""
                              />
                              <img
                                v-if="market.mc === '핸디캡' && checkCart(folder, 'away')"
                                style="width: 8px; margin-top: -3px"
                                src="@/assets/img/ico/white/h.png"
                                alt=""
                              />
                              <img
                                v-if="market.mc === '핸디캡' && !checkCart(folder, 'away')"
                                style="width: 8px; margin-top: -2px"
                                src="@/assets/img/ico/red/h.png"
                                alt=""
                              />
                            </span>
                          </template>
                          <span v-else class="ib">
                            <img style="width: 9px; margin-top: -3px" src="@/assets/img/ico/ico_secret.png" />
                          </span>
                        </dd>
                      </dl>
                    </div>
                    <div class="status">
                      <CountDown :startDate="folder.gamedate"></CountDown>
                    </div>
                    <div class="toggle" v-if="gameIndex === 0 && marketIndex === 0 && folderIndex === 0 && fixture.folderCnt > 1">
                      <button
                        style="background-color: transparent; color: #da2c36; font-weight: bold"
                        :class="{ active: checkArray(fixture) }"
                        @click="toggleDetail(fixture)"
                      >
                        {{ checkArray(fixture) ? "접기" : "+ " + String(Number(fixture.folderCnt) - 1) }}
                      </button>
                    </div>
                    <div class="toggle" v-else></div>
                  </div>
                </transition>
              </div>
            </template>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import CountDown from "./CountDown";
import moment from "moment";

export default {
  name: "Korean",
  props: ["lists", "cartType", "addCart", "checkCart", "checkArray", "openDetailBet", "toggleDetail", "isLocked"],
  components: {
    CountDown,
  },
  data: function () {
    return {
      gameList: [],
    };
  },
  mounted: function () {
    setInterval(this.removeEvents, 1000);
  },
  watch: {
    lists: {
      handler(newVal) {
        this.gameList = newVal.slice();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 시간이 지난 항목들 안보이게 설정
    removeEvents: function () {
      if (this.gameList && this.gameList.length > 0) {
        let endEvents = this.gameList.filter((e) => this.gameStarted(e.gamedate));
        endEvents.forEach((ele) => {
          let index = this.gameList.indexOf(ele);
          if (index >= 0) this.gameList.splice(index, 1);
        });
      }
    },
    gameStarted: function (date) {
      let now = moment().add(9, "hour");
      let startDate = moment(date);
      const remainSeconds = startDate.diff(now, "seconds");
      return this.endTime >= remainSeconds;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.active span {
  color: #ffffff !important;
}

.game-logo {
  margin-left: 10px;
  max-height: 25px;
  width: auto;
  /* max-width: 25px;
  height: auto; */
}
</style>
