<template>
  <section v-if="!this.$store.state.page.isMobile">
    <h2 class="subTitle">E-SPORTS</h2>
    <div class="nbox" style="width: 1261px">
      <div class="contLeft" style="min-height: 1000px; width: 950px">
        <div class="warning_live" style="background-color: #303435; color: white; padding-left: 7px; padding-top: 7px">
          <p>※ 이스포츠 베팅 주의사항 ※</p>
          <br />
          <p>베팅 전 필히 팀명, 세트, 기준점 확인후 베팅해주세요</p>
          <br />
          <p>회원님의 불찰로 인한 베팅의 경우 운영진은 책임지지 않습니다.</p>
          <br />
        </div>
        <Folders v-if="isLoading" :lists="lists" :add-cart="addCart" :check-cart="checkCart" :check-array="checkArray" :open-detail-bet="openDetailBet" :toggle-detail="toggleDetail" :is-locked="isLocked"></Folders>
        <Loading style="text-align: center; margin-top: 20%" v-else></Loading>
      </div>
      <NewCart v-if="user"></NewCart>
      <NewCartNotLogin v-else></NewCartNotLogin>
    </div>
  </section>
  <div v-else>
    <template v-if="isLoading">
      <!-- 게임 데이터 -->
      <div class="m-game3" v-if="originList && originList.length === 0" style="min-height: 100px; text-align: center">
        <div class="day" style="margin-top: 40px">
          <span class="t2" style="text-align: center">진행중인 경기가 없습니다.</span>
        </div>
      </div>
      <!-- 경기목록   -->
      <MWrapperFolder v-else-if="lists.length > 0" :check-cart="checkCart" :add-cart="addCart" :lists="lists"  :check-array="checkArray" :open-detail-bet="openDetailBet" :toggle-detail="toggleDetail" :is-locked="isLocked"></MWrapperFolder>
      <transition name="fade">
        <MCart v-if="user && this.cartItem.length > 0"></MCart>
      </transition>
    </template>
    <template v-else>
      <!-- 인디케이터 -->
      <div class="m-game3" style="min-height: 100px; text-align: center">
        <Loading></Loading>
      </div>
    </template>
  </div>
</template>

<script>
import MCart from "@/components/Betting/Esports/MCart";
import NewCart from "@/components/Betting/Esports/Cart";
import NewCartNotLogin from "@/components/Betting/Esports/CartNotLogin";

import Loading from "@/components/Loading";
import Folders from "@/components/Game/Sports/Esports/Folders";
import MWrapperFolder from "@/components/Game/Sports/Esports/MWrapperFolder";

import { mapState } from "vuex";
import MSG from "@/contants/msg";
export default {
  name: "Esports",
  components: {
    MCart,
    NewCart,
    NewCartNotLogin,
    Folders,
    MWrapperFolder,
    Loading,
  },
  data: function () {
    return {
      category: "",
      isLoading: false,
      search: false,
      keyword: null,
      lists: null,
      sticky: false,
      payload: {},
      isScrollDown: false,
      openDetailBet: [],
    };
  },
  computed: {
    menuVisible(){
            return this.$store.getters['MENU_VISIBLE'];
        },
    esportsVisible(){
      return this.menuVisible?.find(e => e.name === 'E스포츠').visible
    },
    ...mapState({
      originList: (state) => state.esports.originList,
      user: (state) => state.user.user,
      cartItem: (state) => state.esports.cart,
    }),
  },
  mounted: function () {
    if (!this.esportsVisible){
      this.$store.dispatch('SHOW_ERR_MSG3', 'E스포츠는 점검중 입니다.')
    }
    window.addEventListener("scroll", this.handleScroll);
    this.initFolder();
    this.interval1 = setInterval(this.removeEvents, 1000);
    this.interval2 = setInterval(this.initFolder, 10000);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.handleScroll);
    clearInterval(this.interval1);
    clearInterval(this.interval2);
    
    this.$store.dispatch('ALL_CLEAR_ESPORTS_ITEM')
  },
  methods: {
    handleScroll: function () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition > 200) {
        this.isScrollDown = true;
      } else {
        this.isScrollDown = false;
      }
    },
    toggleStick: function () {
      this.sticky = !this.sticky;
    },
    initFolder: function () {
      return this.$store
        .dispatch("GET_ESPORTS_LIST", { payload: this.payload })
        .then(() => {
          this.isLoading = true;
          this.lists = this.originList;
        })
        .catch((err) => {
          alert(err.response.data.error);
        });
    },
    // 베팅카트에 담기
    addCart: function (game, side) {
      if (this.cartItem.find(item => item.gameSeq === game.seq)) {
        return this.$store.dispatch("DELETE_CART_ESPORTS_ITEM_SEQ", game.seq);
      }
      if (this.user) {
        const config = this.user.members_detail_setting;
        if (!config.베팅권한.한국형.active) return this.$store.dispatch("SHOW_ERR_MSG", MSG.error.authorization);
      } else return this.$store.dispatch("SHOW_ERR_MSG", MSG.error.isNotLogin);
      if (!this.canAddBetItem(game, this.cartItem)) {
        return this.$store.dispatch("SHOW_ERR_MSG", MSG.error.combinationError);
      }
      const item = {
        gameId: game.fid,
        gameSeq: game.seq,
        betsSeq: side==="home" ? game.bid1 : side==="away" ? game.bid2 : game.bidx,
        betsId: side==="home" ? game.bid1 : side==="away" ? game.bid2 : game.bidx,
        betSide: side,
        // 승패면 팀명 넣으면 됨 오버언더
        // betsName: side==="home" ? game.hname : side==="away" ? game.aname : game.bidx,
        betsName: this.betsName(game, side),
        betsUpdated: false,
        betsPrevOdds: side === "home" ? game.oh : game.oa,
        sportsId: null,
        marketId: null,
        foldersSeq: null,
        foldersType: null,
        odds: side === "home" ? game.oh : game.oa,
        line: game.baseline,
        leagueNameKr: game.lname,
        leagueNameEn: game.lname,
        leagueNameIcon: null,
        sportNameKr: 'E스포츠',
        sportNameEn: 'E스포츠',
        sportNameIcon: game.null,
        homeNameKr: game.hname,
        homeNameEn: game.hname,
        awayNameKr: game.aname,
        awayNameEn: game.aname,
        marketNameKr: game.mc,
        marketNameEn: game.mc,
        startTime: game.gamedate.replace(' ', 'T')+'.000Z',
        odds1: game.oh,
        odds2: game.oa,
        odds3: game.od,
        betType: "E스포츠",
        detailType: null,
        currentPeriod: 1,
        currentTime: 1,
        homeScore: 0,
        awayScore: 0,
        penalty: null,
      };
      this.$store.dispatch("ADD_CART_ESPORTS_ITEM", item);
    }, // 카트내역 체크해서 표시
    checkCart: function (game, side) {
      let index = this.cartItem.find((item) => item.gameSeq === game.seq && item.betSide === side);
      return !!index;
    },
    checkArray: function (fixture) {
      // fixture의 fid가 openDetailBet 배열에 있는지 확인
      return this.openDetailBet.includes(fixture.fid);
    },
    toggleDetail: function (fixture) {
      const fid = fixture.fid;
      const index = this.openDetailBet.indexOf(fid);
      if (index === -1) {
        // 없으면 추가 (펼치기)
        this.openDetailBet.push(fid);
      } else {
        // 있으면 제거 (접기)
        this.openDetailBet.splice(index, 1);
      }
    },
    isLocked: function (folder, side) {
      return !(folder.betstatus === '1' && folder.isLimitBet === 'N' && folder[side] !== '1.00');
    },
    betsName: function (game, side) {
      if(game.mc === '승패') return side === "home" ? game.hname : side === "away" ? game.aname : game.bidx;
      if(game.mc === '핸디캡') return side === "home" ? game.hname + `(${Number(game.baseline)})` : side === "away" ? game.aname + `(${Number(game.baseline) * -1})` : game.bidx;
      if(game.mc === '오버언더') return side === "home" ? `오버(${game.baseline})` : side === "away" ? `언더(${game.baseline})` : game.bidx;
      return side === "home" ? game.hname : side === "away" ? game.aname : game.bidx;
    },
    canAddBetItem: function (newGame, cart) {
  // 동일 fid인 항목만 대상 (fid가 다르면 제한 없음)
  const sameFidItems = cart.filter(item => item.gameId === newGame.fid);
  if (sameFidItems.length === 0) {
    return true;
  }
  
  // --- 헬퍼 함수: LOL 리그용 타입 추출 ---
  // 기준: hname에 distinguishing keyword가 없으면 plain하게 mc값만으로 타입을 결정합니다.
  // distinguishing keyword: "킬수핸디", "킬합", "경기시간", "종합승패", "종합핸디"
  // plain한 경우 (즉, 아무 keyword도 없으면):
  //   mc === "승패"      → 타입 "A"
  //   mc === "핸디캡"    → 타입 "B"
  //   mc === "오버언더"  → 타입 "C"
  // 만약 hname에 "종합승패" 또는 "종합핸디", "첫바론", "첫킬"이 포함되면 → 타입 "X" (조합 불가)
  // 아니라면, keyword가 있는 경우에는:
  //   "킬수핸디" 포함 → "B"
  //   "킬합" 포함    → "C"
  //   "경기시간" 포함 → "D"
  const getTypeForLOL = (hname, mc) => {
    // keyword가 있는 경우
    if (hname.includes("종합승패") || hname.includes("종합핸디") || hname.includes("첫킬") || hname.includes("첫바론")) {
      return "X"; // 조합 불가 대상으로 처리
    }
    // plain 여부: distinguishing keyword가 전혀 없으면 plain
    const isPlain = !(
      hname.includes("킬수핸디") ||
      hname.includes("킬합") ||
      hname.includes("경기시간") ||
      hname.includes("종합승패") ||
      hname.includes("종합핸디")
    );
    if (isPlain) {
      // plain한 경우라도 mc에 따라 LOL 조합 규칙의 타입으로 할당
      if (mc === "승패") return "A";
      if (mc === "핸디캡") return "B";
      if (mc === "오버언더") return "C";
      return undefined;
    }
    
    if (hname.includes("킬수핸디")) return "B";
    if (hname.includes("킬합")) return "C";
    if (hname.includes("경기시간")) return "D";
    return undefined;
  };

  // --- 헬퍼 함수: non-LOL 리그용 타입 추출 ---
  // non-LOL 리그에서는 hname은 "팀명 1세트" 형태로 나오며, mc 값에 따라 구분합니다.
  // 단, 만약 hname에 "종합승패" 또는 "종합핸디"가 포함되면 "X"를 반환하여,
  // 해당 항목은 어떤 다른 항목과도 조합되지 않도록 합니다.
  //   mc "승패"      → 타입 "S"
  //   mc "핸디캡"    → 타입 "H"
  //   mc "오버언더"  → 타입 "O"
  const getTypeForNonLOL = (hname, mc) => {
    if (hname.includes("종합승패") || hname.includes("종합핸디")) {
      return "X";
    }
    if (mc === "승패") return "S";
    if (mc === "핸디캡") return "H";
    if (mc === "오버언더") return "O";
    return undefined;
  };

  // --- 조합 제한 체크 ---
  // fid가 같은 경우, lname에 "LOL" 포함 여부에 따라 다른 로직 적용
  const newIsLOL = newGame.lname.includes("LOL");
  const newType = newIsLOL
    ? getTypeForLOL(newGame.hname, newGame.mc)
    : getTypeForNonLOL(newGame.hname, newGame.mc);
  
  for (const item of sameFidItems) {
    // 동일 fid 내라도, 리그 유형(LOL 포함 여부)이 다르면 매트릭스 규칙 적용 안 함
    const itemIsLOL = item.leagueNameKr.includes("LOL");
    if (newIsLOL !== itemIsLOL) continue;
    
    if (newIsLOL) {
      // [LOL 리그의 허용 조합]
      // 허용되는 조합: (A, C), (A, D), (B, C), (B, D) (순서 상관없이)
      // 만약 newType이나 itemType이 "X" (종합승패/종합핸디, 첫킬/첫바론)라면 바로 불가 처리.
      const itemType = getTypeForLOL(item.homeNameKr, item.marketNameKr);
      if (newType === "X" || itemType === "X") {
        return false;
      }
      // 두 타입을 정렬하여 문자열 결합
      const pair = [newType, itemType].sort().join("-");
      const allowedPairs = ["A-C", "A-D", "B-C", "B-D"];
      if (!allowedPairs.includes(pair)) return false;
    } else {
      // [non-LOL 리그의 허용 조합]
      // 이제 non-LOL 리그에서도 hname에 "종합승패"/"종합핸디"가 붙은 경우는 "X"가 되어
      // 어떤 조합도 허용되지 않습니다.
      const itemType = getTypeForNonLOL(item.homeNameKr, item.marketNameKr);
      if (newType === "X" || itemType === "X") {
        return false;
      }
      // 허용되는 조합: 오버언더(O)와 승패(S) 또는 핸디캡(H)끼리 (순서 상관없이)
      if (
        (newType === "O" && (itemType === "S" || itemType === "H")) ||
        ((newType === "S" || newType === "H") && itemType === "O")
      ) {
        continue;
      } else {
        return false;
      }
    }
  }
  // 모든 기존 항목과의 조합 검사를 통과하면 추가 가능
  return true;
}



  },
};
</script>

<style scoped>
.event-detail-list-move {
  transition: transform 0.8s;
}
.event-detail-list {
  transition: transform 0.8s;
}
.fixedCart {
  top: 80px;
}
</style>
