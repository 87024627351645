<template>
  <div style="min-height: 120px; margin-top: 5px;" v-if="isLoading">
    <div class="categorys2" v-if="isLoading && lists">
      <button style="width: 60px;" @click="selectMarket('STAR')">
        <img src="@/assets/img/ico/favor_on.png">
      </button>
      <button @click="selectMarket(null)">
        전체
      </button>
      <button @click="selectMarket('승무패')">
        승무패
      </button>
      <button @click="selectMarket('핸디캡')">
        핸디캡
      </button>
      <button @click="selectMarket('오버언더')">
        오버언더
      </button>
      <button @click="selectMarket('ETC')">
        기타
      </button>
    </div>

    <template v-if="!selectedMarket">
      <div class="m-game2" v-for="(row, index) in marketSort(originList)" :key="'mobileDetailMarket'+index">
        <!--  마켓시퀀스 필터링 -->
        <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
          <!--  마켓시퀀스 기간별 -->
          <template v-for="period in uniqPeriod(row.id)">
            <div class="head" :key="'game'+game.seq+period"
                 v-if="lists.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))">
              <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
                {{ row | marketName(period) }}
              </div>
              <div class="tit" v-else>{{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}</div>
              <div class="right">
                <span @click="folderClosed(row.seq, period)" class="material-icons" :class="{'up': closed.find(e => e.seq === e.period === period), 'down': !closed.find(e => e.seq === e.period === period)}">expand_more</span>
              </div>
            </div>
            <div class="body" :key="'gameFolders'+game.seq+period" v-if="!closed.find(e => e.seq === e.period === period)">
              <template
                  v-for="(row2, index2) in lists.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))"
              >
                <div class="item"
                     :key="'marketFolderDetail'+index2">
                  <template v-if="(row2.isSuspended === 'y' && row2.isVisible === 'y') || game.isSuspended === 'y'">
                    <MDetailLock></MDetailLock>
                    <MDetailLock
                        v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                    ></MDetailLock>
                    <MDetailLock></MDetailLock>
                  </template>
                  <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y'">
                    <MDetailPrice
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home' || e.name === 'Team1')"
                        :folder="row2"
                        :game="game"
                        :team="game.homeTeam"
                    >
                    </MDetailPrice>
                    <MDetailPrice
                        v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                        :folder="row2"
                        :game="game"
                        :team="game.homeTeam"
                    >
                    </MDetailPrice>
                    <MDetailPrice
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away' || e.name === 'Team2')"
                        :folder="row2"
                        :game="game"
                        :team="game.awayTeam"
                    >
                    </MDetailPrice>
                    <!--              <dl-->
                    <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                    <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                    <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                    <!--              >-->
                    <!--                <dt>-->
                    <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                    <!--                </dt>-->
                    <!--                <dd>-->
                    <!--              <span-->
                    <!--                  v-if="home.name === 'Over'"-->
                    <!--                  class="material-icons up"-->
                    <!--              >expand_more-->
                    <!--              </span>-->
                    <!--                  {{ home.price }}-->
                    <!--                </dd>-->
                    <!--              </dl>-->
                    <!--              <dl-->
                    <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                    <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                    <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                    <!--              >-->
                    <!--                <dt>-->
                    <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                    <!--                </dt>-->
                    <!--                <dd>-->
                    <!--                  {{ draw.price }}-->
                    <!--                </dd>-->
                    <!--              </dl>-->
                    <!--              <dl-->
                    <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                    <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                    <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                    <!--              >-->
                    <!--                <dt>-->
                    <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                    <!--                </dt>-->
                    <!--                <dd>-->
                    <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                    <!--                  {{ away.price }}-->
                    <!--                </dd>-->
                    <!--              </dl>-->
                  </template>
                </div>
              </template>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="head" v-if="lists.filter(e => String(e.marketId) === String(row.id))?.find(f => f.isVisible ==='y')">
            <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
              {{ row | marketName }}
            </div>
            <div class="tit" v-else>{{ row | teamMarketName(game.homeTeam, game.awayTeam) }}</div>
            <div class="right">
              <span @click="folderClosed(row.seq, '0')" class="material-icons" :class="{'up': closed.find(e => e.seq === row.seq && e.period === '0'), 'down': !closed.find(e => e.seq === row.seq && e.period === '0')}">expand_more</span>
            </div>
          </div>
          <div class="body" v-if="!closed.find(e => e.seq === row.seq &&  e.period === '0')">
            <template
                v-if="row2.bets.length < 4"
                v-for="(row2, index2) in lists.filter(e => String(e.marketId) === String(row.id))"
            >
              <div class="item" :key="'marketFolderDetail'+index2">
                <template v-if="(row2.isSuspended === 'y' && row2.isVisible === 'y') || game.isSuspended === 'y'">
                  <MDetailLock></MDetailLock>
                  <MDetailLock
                      v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                  ></MDetailLock>
                  <MDetailLock></MDetailLock>
                </template>
                <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y'">
                  <MDetailPrice
                      :add-cart="addCart"
                      :check-cart="checkCart"
                      :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home' || e.name === 'Team1')"
                      :folder="row2"
                      :game="game"
                      :team="game.homeTeam"
                  >
                  </MDetailPrice>
                  <MDetailPrice
                      v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                      :add-cart="addCart"
                      :check-cart="checkCart"
                      :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'NoGoal')"
                      :folder="row2"
                      :game="game"
                      :team="game.homeTeam"
                  >
                  </MDetailPrice>
                  <MDetailPrice
                      :add-cart="addCart"
                      :check-cart="checkCart"
                      :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away' || e.name === 'Team2')"
                      :folder="row2"
                      :game="game"
                      :team="game.awayTeam"
                  >
                  </MDetailPrice>
                  <!--              <dl-->
                  <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                  <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                  <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                  <!--              >-->
                  <!--                <dt>-->
                  <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                  <!--                </dt>-->
                  <!--                <dd>-->
                  <!--              <span-->
                  <!--                  v-if="home.name === 'Over'"-->
                  <!--                  class="material-icons up"-->
                  <!--              >expand_more-->
                  <!--              </span>-->
                  <!--                  {{ home.price }}-->
                  <!--                </dd>-->
                  <!--              </dl>-->
                  <!--              <dl-->
                  <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                  <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                  <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                  <!--              >-->
                  <!--                <dt>-->
                  <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                  <!--                </dt>-->
                  <!--                <dd>-->
                  <!--                  {{ draw.price }}-->
                  <!--                </dd>-->
                  <!--              </dl>-->
                  <!--              <dl-->
                  <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                  <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                  <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                  <!--              >-->
                  <!--                <dt>-->
                  <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                  <!--                </dt>-->
                  <!--                <dd>-->
                  <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                  <!--                  {{ away.price }}-->
                  <!--                </dd>-->
                  <!--              </dl>-->
                </template>
              </div>
            </template>
            <template v-else-if="row2.bets.length === 4">
              <template v-for="l in arrLenDiv(row2.bets, 2)">
                <template v-if="(row2.isVisible === 'y' && row2.isSuspended === 'y') || game.isSuspended === 'y'">
                  <div class="item" :key="'CorrectScoreBets' + l">
                    <dl
                        v-for="bet in row2.bets.slice((l - 1) * 2, l * 2)"
                        :key="'betsDetail'+bet.id"
                        style="width: 33%;"
                    >
                      <dt>
                        {{ manyBetsName(bet)}} {{ bet.line }}
                      </dt>
                      <dd>
                        <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                      </dd>
                    </dl>
                  </div>
                </template>
                <template v-else>
                  <div class="item" :key="'CorrectScoreBets' + l">
                    <dl
                        v-for="bet in row2.bets.slice((l - 1) * 2, l * 2)"
                        :class="{'active': checkCart(bet)}"
                        @click="addCart(bet, row2, game)"
                        :key="'betsDetail'+bet.id"
                        style="width: 33%;"
                    >
                      <dt>
                        {{ manyBetsName(bet)}} {{ bet.line }}
                      </dt>
                      <dd>
                        {{ bet.price }}
                      </dd>
                    </dl>
                  </div>
                </template>
              </template>

            </template>
            <template v-else>
              <template v-if="row2.market.nameEn === 'Players Specials and Match Winner'">
                <template v-for="l in arrLenDiv(row2.bets, 2)">
                  <template v-if="(row2.isVisible === 'y' && row2.isSuspended === 'y') || game.isSuspended === 'y'">
                    <div class="item" :key="'CorrectScoreBets' + l">
                      <dl
                          v-for="bet in row2.bets.slice((l - 1) * 2, l * 2)"
                          :key="'betsDetail'+bet.id"
                          style="width: 33%;"
                      >
                        <dt>
                          {{ manyBetsName(bet)}}
                        </dt>
                        <dd>
                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                        </dd>
                      </dl>
                    </div>
                  </template>
                  <template v-else>
                    <div class="item" :key="'CorrectScoreBets' + l">
                      <dl
                          v-for="bet in row2.bets.slice((l - 1) * 2, l * 2)"
                          :class="{'active': checkCart(bet)}"
                          @click="addCart(bet, row2, game)"
                          :key="'betsDetail'+bet.id"
                          style="width: 33%;"
                      >
                        <dt>
                          {{ manyBetsName(bet)}}
                        </dt>
                        <dd>
                          {{ bet.price }}
                        </dd>
                      </dl>
                    </div>
                  </template>
                </template>

              </template>
              <template v-else>
                <template v-for="l in arrLenDiv(row2.bets, 3)">
                  <template v-if="(row2.isVisible === 'y' && row2.isSuspended === 'y') || game.isSuspended === 'y'">
                    <div class="item" :key="'CorrectScoreBets' + l">
                      <dl
                          v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)"
                          :key="'betsDetail'+bet.id"
                          style="width: 33%;"
                      >
                        <dt>
                          {{ manyBetsName(bet)}}
                        </dt>
                        <dd>
                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                        </dd>
                      </dl>
                    </div>
                  </template>
                  <template v-else>
                    <div class="item" :key="'CorrectScoreBets' + l">
                      <dl
                          v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)"
                          :class="{'active': checkCart(bet)}"
                          @click="addCart(bet, row2, game)"
                          :key="'betsDetail'+bet.id"
                          style="width: 33%;"
                      >
                        <dt>
                          {{ manyBetsName(bet)}}
                        </dt>
                        <dd>
                          {{ bet.price }}
                        </dd>
                      </dl>
                    </div>
                  </template>
                </template>

              </template>

            </template>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="m-game2" v-for="(row, index) in marketSort(lists)" :key="'mobileDetailMarket'+index">
        <!--  마켓시퀀스 필터링 -->
        <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
          <!--  마켓시퀀스 기간별 -->
          <template v-for="period in uniqPeriod(row.seq)">
            <div class="head" :key="'game'+game.seq+period">
              <div class="tit">{{ row | marketName(period) }}</div>
              <div class="right">
                <span @click="folderClosed(row.seq, period)" class="material-icons" :class="{'up': closed.find(e => e.seq === row.seq && e.period === period), 'down': !closed.find(e => e.seq === row.seq && e.period === period)}">expand_more</span>
              </div>
            </div>
            <div class="body" :key="'gameFolders'+game.seq+period" v-if="!closed.find(e => e.seq === row.seq && e.period === period)">
              <template
                  v-for="(row2, index2) in lists.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))"
              >
                <div class="item"
                     :key="'marketFolderDetail'+index2">
                  <template v-if="(row2.isSuspended === 'y' && row2.isVisible === 'y') || game.isSuspended === 'y'">
                    <MDetailLock></MDetailLock>
                    <MDetailLock
                        v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                    ></MDetailLock>
                    <MDetailLock></MDetailLock>
                  </template>
                  <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y'">
                    <MDetailPrice
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home')"
                        :folder="row2"
                        :game="game"
                        :team="game.homeTeam"
                    >
                    </MDetailPrice>
                    <MDetailPrice
                        v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                        :folder="row2"
                        :game="game"
                        :team="game.homeTeam"
                    >
                    </MDetailPrice>
                    <MDetailPrice
                        :add-cart="addCart"
                        :check-cart="checkCart"
                        :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away')"
                        :folder="row2"
                        :game="game"
                        :team="game.awayTeam"
                    >
                    </MDetailPrice>
                    <!--              <dl-->
                    <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                    <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                    <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                    <!--              >-->
                    <!--                <dt>-->
                    <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                    <!--                </dt>-->
                    <!--                <dd>-->
                    <!--              <span-->
                    <!--                  v-if="home.name === 'Over'"-->
                    <!--                  class="material-icons up"-->
                    <!--              >expand_more-->
                    <!--              </span>-->
                    <!--                  {{ home.price }}-->
                    <!--                </dd>-->
                    <!--              </dl>-->
                    <!--              <dl-->
                    <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                    <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                    <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                    <!--              >-->
                    <!--                <dt>-->
                    <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                    <!--                </dt>-->
                    <!--                <dd>-->
                    <!--                  {{ draw.price }}-->
                    <!--                </dd>-->
                    <!--              </dl>-->
                    <!--              <dl-->
                    <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                    <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                    <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                    <!--              >-->
                    <!--                <dt>-->
                    <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                    <!--                </dt>-->
                    <!--                <dd>-->
                    <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                    <!--                  {{ away.price }}-->
                    <!--                </dd>-->
                    <!--              </dl>-->
                  </template>
                </div>
              </template>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="head">
            <div class="tit" v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0">
              {{ row | marketName }}
            </div>
            <div class="tit" v-else>{{ row | teamMarketName(game.homeTeam, game.awayTeam) }}</div>
            <div class="right">
              <span @click="folderClosed(row.seq, '0')" class="material-icons" :class="{'up': closed.find(e => e.seq === row.seq && e.period === '0'), 'down': !closed.find(e => e.seq === row.seq && e.period === '0')}">expand_more</span>
            </div>
          </div>
          <div class="body" v-if="!closed.find(e => e.seq === row.seq && e.period === '0')">
            <template
                v-if="row2.bets.length < 4"
                v-for="(row2, index2) in lists.filter(e => String(e.marketId) === String(row.id))"
            >
              <div class="item" :key="'marketFolderDetail'+index2">
                <template v-if="(row2.isSuspended === 'y' && row2.isVisible === 'y') || game.isSuspended === 'y'">
                  <MDetailLock></MDetailLock>
                  <MDetailLock
                      v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                  ></MDetailLock>
                  <MDetailLock></MDetailLock>
                </template>
                <template v-else-if="row2.isSuspended === 'n' && row2.isVisible === 'y'">
                  <MDetailPrice
                      :add-cart="addCart"
                      :check-cart="checkCart"
                      :bets="row2.bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes' || e.name === 'Home')"
                      :folder="row2"
                      :game="game"
                      :team="game.homeTeam"
                  >
                  </MDetailPrice>
                  <MDetailPrice
                      v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                      :add-cart="addCart"
                      :check-cart="checkCart"
                      :bets="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"
                      :folder="row2"
                      :game="game"
                      :team="game.homeTeam"
                  >
                  </MDetailPrice>
                  <MDetailPrice
                      :add-cart="addCart"
                      :check-cart="checkCart"
                      :bets="row2.bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No' || e.name === 'Away')"
                      :folder="row2"
                      :game="game"
                      :team="game.awayTeam"
                  >
                  </MDetailPrice>
                  <!--              <dl-->
                  <!--                  :set="home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes')"-->
                  <!--                  :class="{'active': checkCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'))}"-->
                  <!--                  @click="addCart(home=row2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Yes'), row2)"-->
                  <!--              >-->
                  <!--                <dt>-->
                  <!--                  {{ home.name | betsName }} {{row2.folders_line | lineSet2(home.name)}}-->
                  <!--                </dt>-->
                  <!--                <dd>-->
                  <!--              <span-->
                  <!--                  v-if="home.name === 'Over'"-->
                  <!--                  class="material-icons up"-->
                  <!--              >expand_more-->
                  <!--              </span>-->
                  <!--                  {{ home.price }}-->
                  <!--                </dd>-->
                  <!--              </dl>-->
                  <!--              <dl-->
                  <!--                  v-if="row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal')"-->
                  <!--                  :class="{'active': checkCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'))}"-->
                  <!--                  @click="addCart(draw=row2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'No Goal'), row2)"-->
                  <!--              >-->
                  <!--                <dt>-->
                  <!--                  {{ draw.name | betsName }} {{ draw.line }}-->
                  <!--                </dt>-->
                  <!--                <dd>-->
                  <!--                  {{ draw.price }}-->
                  <!--                </dd>-->
                  <!--              </dl>-->
                  <!--              <dl-->
                  <!--                  :set="away=row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No')"-->
                  <!--                  :class="{'active': checkCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'))}"-->
                  <!--                  @click="addCart(row2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'No'), row2)"-->
                  <!--              >-->
                  <!--                <dt>-->
                  <!--                  {{ away.name | betsName}} {{ row2.folders_line | lineSet2(away.name, '2')}}-->
                  <!--                </dt>-->
                  <!--                <dd>-->
                  <!--                  <span v-if="away.name === 'Under'" class="material-icons down">expand_more</span>-->
                  <!--                  {{ away.price }}-->
                  <!--                </dd>-->
                  <!--              </dl>-->
                </template>
              </div>
            </template>
            <template v-else-if="row2.bets.length === 4">
              <template v-for="l in arrLenDiv(row2.bets, 2)">
                <template v-if="(row2.isVisible === 'y' && row2.isSuspended === 'y') || game.isSuspended === 'y'">
                  <div class="item" :key="'CorrectScoreBets' + l">
                    <dl
                        v-for="bet in row2.bets.slice((l - 1) * 2, l * 2)"
                        :key="'betsDetail'+bet.id"
                        style="width: 33%;"
                    >
                      <dt>
                        {{ manyBetsName(bet)}} {{ bet.line }}
                      </dt>
                      <dd>
                        <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                      </dd>
                    </dl>
                  </div>
                </template>
                <template v-else>
                  <div class="item" :key="'CorrectScoreBets' + l">
                    <dl
                        v-for="bet in row2.bets.slice((l - 1) * 2, l * 2)"
                        :class="{'active': checkCart(bet)}"
                        @click="addCart(bet, row2, game)"
                        :key="'betsDetail'+bet.id"
                        style="width: 33%;"
                    >
                      <dt>
                        {{ manyBetsName(bet)}} {{ bet.line }}
                      </dt>
                      <dd>
                        {{ bet.price }}
                      </dd>
                    </dl>
                  </div>
                </template>
              </template>

            </template>
            <template v-else>
              <template v-if="row2.market.nameEn === 'Players Specials and Match Winner'">
                <template v-for="l in arrLenDiv(row2.bets, 2)">
                  <template v-if="(row2.isVisible === 'y' && row2.isSuspended === 'y') || game.isSuspended === 'y'">
                    <div class="item" :key="'CorrectScoreBets' + l">
                      <dl
                          v-for="bet in row2.bets.slice((l - 1) * 2, l * 2)"
                          :key="'betsDetail'+bet.id"
                          style="width: 33%;"
                      >
                        <dt>
                          {{ manyBetsName(bet)}}
                        </dt>
                        <dd>
                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                        </dd>
                      </dl>
                    </div>
                  </template>
                  <template v-else>
                    <div class="item" :key="'CorrectScoreBets' + l">
                      <dl
                          v-for="bet in row2.bets.slice((l - 1) * 2, l * 2)"
                          :class="{'active': checkCart(bet)}"
                          @click="addCart(bet, row2, game)"
                          :key="'betsDetail'+bet.id"
                          style="width: 33%;"
                      >
                        <dt>
                          {{ manyBetsName(bet)}}
                        </dt>
                        <dd>
                          {{ bet.price }}
                        </dd>
                      </dl>
                    </div>
                  </template>
                </template>

              </template>
              <template v-else>
                <template v-for="l in arrLenDiv(row2.bets, 3)">
                  <template v-if="(row2.isVisible === 'y' && row2.isSuspended === 'y') || game.isSuspended === 'y'">
                    <div class="item" :key="'CorrectScoreBets' + l">
                      <dl
                          v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)"
                          :key="'betsDetail'+bet.id"
                          style="width: 33%;"
                      >
                        <dt>
                          {{ manyBetsName(bet)}}
                        </dt>
                        <dd>
                          <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                        </dd>
                      </dl>
                    </div>
                  </template>
                  <template v-else>
                    <div class="item" :key="'CorrectScoreBets' + l">
                      <dl
                          v-for="bet in row2.bets.slice((l - 1) * 3, l * 3)"
                          :class="{'active': checkCart(bet)}"
                          @click="addCart(bet, row2, game)"
                          :key="'betsDetail'+bet.id"
                          style="width: 33%;"
                      >
                        <dt>
                          {{ manyBetsName(bet)}}
                        </dt>
                        <dd>
                          {{ bet.price }}
                        </dd>
                      </dl>
                    </div>
                  </template>
                </template>

              </template>

            </template>
          </div>
        </template>
      </div>
    </template>
  </div>
  <div style="text-align: center; min-height: 120px;" v-else>
    <Loading style="margin-top: 30px; "></Loading>
  </div>
</template>

<script>
import MDetailLock from '@/components/Game/Sports/European/MDetailLock'
import MDetailPrice from '@/components/Game/Sports/European/MDetailPrice'
import Loading from '@/components/Loading'
import MSG from '@/contants/msg'
import {mapState} from "vuex";

export default {
  name: "Detail",
  components: {
    MDetailLock,
    MDetailPrice,
    Loading,
  },
  props: ['game'],
  data: function () {
    return {
      path: 'v2/game/european/detail',
      cartType: 'sports',
      originList: null,
      lists: null,
      isLoading: false,
      selectedMarket: null,
      closed: [],
    }
  },
  mounted: function () {
    this.$store.dispatch('CLEAR_SPORT_CART', '프리매치(유럽형)')
    this.initFolder();
    setTimeout(() => {
      this.isLoading = true;
    }, 2000);
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
  beforeDestroy() {
    this.$socket.client.off('prematch');
  },
  watch: {
    selectedMarket: function (val) {
      if (!val) return this.lists = this.originList;
      if (val === '승무패') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11557, 11558, 11575, 11649, 11930].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11603, 11604, 11611].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 212) return [11531, 11537, 11667].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11521, 11544].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11622, 11629, 11625].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 220) return [11515, 11507, 12972, 13103].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12240, 12242, 12243, 12244, 12245, 12246, 16817, 16818].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === '핸디캡') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11562, 11563, 11580, 11752, 11968].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11608, 11615, 11615, 11615].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 212) return [11532, 11539, 11668].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11904, 11724, 11546].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11623, 11631, 11626].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 220) return [11516, 11509, 17055].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12651, 12775, 12786, 12797, 12808, 12823].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === '오버언더') {
        this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11559, 11560, 11561, 11577, 11753, 11969, 11592, 11586].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11605, 11607, 11606, 11612, 11612, 11612, 11613, 11613, 11613, 11614, 11614, 11614].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 212) return [11533, 11534, 11535, 11662, 11663, 11664, 11669].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11726, 11723, 11720, 12104, 11545].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 220) return [11512, 11510, 11511, 13655, 11508, 17056, 13106].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12651, 12776, 12787, 12798, 12809, 12824].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === 'ETC') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11573, 11574, 11923, 12930, 11684, 11685, 11567, 11572, 11568, 11727, 13619, 11732].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11652, 11653, 11654, 11658, 11659, 11656, 11717, 11718].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 212) return [11536, 16637, 12530, 12531, 12566, 12567, 12568, 13558, 14049].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11522, 11540, 11543, 11675, 12105].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 220) return [13102, 13101].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12646, 12648, 12653, 12812, 12827, 12777, 12788, 12799, 12813, 12828, 12645, 12647, 12652, 12810, 12825, 12780, 12791, 12802, 12816, 12831, 12778, 12789, 12800, 12814, 12829, 12782, 12793, 12804, 12818, 12833, 12783, 12794, 12805, 12819, 12834, 12784, 12795, 12806, 12820, 12835].indexOf(e.marketSeq) >= 0
        });
      }

      // if (val === 'STAR') {
      //   console.log(this.stared);
      //   this.lists = [];
      //   this.stared.map(s => {
      //     const lists = this.originList.filter(l => String(s.gameId) === String(this.game.id) && String(s.seq) === String(l.marketSeq) && String(s.periodSequence) === String(l.periodSequence))
      //     lists.map(folder => this.lists.push(folder));
      //   })
      //
      //   return;
      // }


      this.lists = this.originList.filter(e => e.market.nameKr?.indexOf(val) >= 0);
    }
  },
  methods: {
    initFolder: function () {
      const payload = {
        gameId: this.game.id
      }

      return this.$store.dispatch('GET_DETAIL',  { payload })
          .then(data => {
            let result = null;
            if (Array.isArray(data)){
              result = data[0].data;
            } else {
              result = data.data;
            }
            this.game = result.payload.game;
            this.originList = result.payload.list;
            this.lists = this.originList;
            this.isLoading = true;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    // 마켓선택
    selectMarket: function (market) {
      this.selectedMarket = market;
    },
    // 마켓정렬
    marketSort: function (lists) {
      const markets = lists.map(e => e.market);
      const arrUnique = markets.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    // 베팅카트에 담기
    addCart: function (bets, folders, game) {
      if (!this.user) return alert(MSG.isNotLogin);
      const config = this.user.members_detail_setting;
      if (!config.베팅권한.유럽형.active) {
        return alert(MSG.authorization);
      }
      if (bets.name === '') bets.name = bets.displayName;
      const cartItem = {
        gameId: game.id,
        gameSeq: game.seq,
        betsSeq: bets.seq,
        betsStatus: bets.status,
        betsId: bets.id,
        betsName: bets.name,
        betsUpdated: false,
        betsPrevOdds: bets.price,
        sportsId: game.sportSeq,
        marketsId: folders.marketSeq,
        foldersSeq: folders.seq,
        foldersType: folders.type,
        odds: bets.price,
        line: bets.line ? bets.line : null,
        leagueNameKr: game.league.nameKr,
        leagueNameEn: game.league.nameEn,
        leagueNameIcon: game.league.icon,
        sportNameKr: game.sport.nameKr,
        sportNameEn: game.sport.nameEn,
        sportNameIcon: game.sport.icon,
        homeNameKr: game.homeTeam.nameKr,
        homeNameEn: game.homeTeam.nameEn,
        awayNameKr: game.awayTeam.nameKr,
        awayNameEn: game.awayTeam.nameEn,
        marketNameKr: folders.market.nameKr,
        marketNameEn: folders.market.nameEn,
        maxAmount: game.league?.maxAmount,
        startTime: game.startTime,
        odds1: null,
        odds2: null,
        odds3: null,
        betType: '프리매치(유럽형)',
        detailType: null,
        currentPeriod: game.currentPeriod,
        currentTime: game.currentTime,
        homeScore: game.homeResult,
        awayScore: game.awayResult,
        penalty: null
      };

      if (folders.periodSequence !== '0') {
        cartItem.marketNameKr = folders.market.nameKr?.replace('{sw}', folders.periodSequence);
        cartItem.marketNameEn = folders.market.nameEn?.replace('{sw}', folders.periodSequence);
        if (folders.market.nameEn.indexOf('Player Points') >= 0 ||
            folders.market.nameEn.indexOf('Player Rebounds') >= 0 ||
            folders.market.nameEn.indexOf('Player Assists') >= 0 ||
            folders.market.nameEn.indexOf('Player Points and Assists') >= 0 ||
            folders.market.nameEn.indexOf('Player Points and Rebounds') >= 0 ||
            folders.market.nameEn.indexOf('Player Assists and Rebounds') >= 0 ||
            folders.market.nameEn.indexOf('Player Points, Assists and Rebounds') >= 0){
          const playerName = folders.name.split(')')[0];
          cartItem.marketNameKr = `${playerName}) ${cartItem.marketNameKr || cartItem.marketNameEn}`
          cartItem.marketNameEn = `${playerName}) ${cartItem.marketNameEn}`
        }

        if (
            folders.market.nameEn.indexOf('Player Total Kills') >= 0 ||
            folders.market.nameEn.indexOf('Player Total Assists') >= 0
        ){
          const playerName = folders.name.split(' ')[0];
          cartItem.marketNameKr = `${playerName} ${cartItem.marketNameKr || cartItem.marketNameEn}`
          cartItem.marketNameEn = `${playerName} ${cartItem.marketNameEn}`
        }
      }

      if (cartItem.marketNameKr?.indexOf('1팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('1팀', cartItem.homeNameKr || cartItem.homeNameEn)
      }

      if (cartItem.marketNameKr?.indexOf('2팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('2팀', cartItem.awayNameKr || cartItem.awayNameEn)
      }

      if (folders.market.nameEn?.includes('Player To Record a Hit')){
        cartItem.betsName = bets.displayName
      }

      this.$store.dispatch('ADD_EUROPEAN_CART_ITEM', cartItem)
    },
    addStartedMarket: function (seq, period) {
      console.log(seq, period);
      const market = {
        gameId: this.game.id,
        seq: seq,
        periodSequence: period,
      }
      this.$store.dispatch('list/started', market);
    },
    manyBetsName: function (bet) {
      // let name = this.bet.name;
      let name = bet.displayName;
      if (name?.indexOf('W1 and Over') >= 0){
        name = name.replace('W1 and Over', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 오버`)
      }
      if (name?.indexOf('W2 and Over') >= 0){
        name = name.replace('W2 and Over', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 오버`)
      }
      if (name?.indexOf('12 and Over') >= 0){
        name = name.replace('12 and Over', '12 & 오버')
      }
      if (name?.indexOf('W1 and Under') >= 0){
        name = name.replace('W1 and Under', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 언더`)
      }
      if (name?.indexOf('W2 and Under') >= 0){
        name = name.replace('W2 and Under', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 언더`)
      }
      if (name?.indexOf('12 and Under') >= 0){
        name = name.replace('12 and Under', '12 & 언더')
      }

      if (name?.indexOf('1X and Over') >= 0){
        name = name.replace('1X and Over', '1X & 오버')
        return name;
      }
      if (name?.indexOf('Draw and Over') >= 0){
        name = name.replace('Draw and Over', '무 & 오버')
      }
      if (name?.indexOf('X and Over') >= 0){
        name = name.replace('X and Over', '무 & 오버')
      }
      if (name?.indexOf('X2 and Over') >= 0){
        name = name.replace('X2 and Over', 'X2 & 오버')
      }
      if (name?.indexOf('1X and Under') >= 0){
        name = name.replace('1X and Under', '1X & 언더')
      }
      if (name?.indexOf('Draw And Under') >= 0){
        name = name.replace('Draw And Under', '무 & 언더')
      }
      if (name?.indexOf('X And Under') >= 0){
        name = name.replace('X And Under', '무 & 언더')
      }
      if (name?.indexOf('X2 and Under') >= 0){
        name = name.replace('X2 and Under', 'X2 & 언더')
      }
      if (name?.indexOf('ormore') >= 0){
        name = name.replace('ormore', '골 또는 그이상')
      }
      if (name === 'AnyOther'){
        name = name.replace('AnyOther', '기타')
      }
      if (name === ''){
        name = bet.displayName
      }
      if (name?.indexOf('W1andOver') >= 0){
        name = name.replace('W1andOver', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 오버`)
      }
      if (name?.indexOf('W2andOver') >= 0){
        name = name.replace('W2andOver', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 오버`)
      }
      if (name?.indexOf('12andOver') >= 0){
        name = name.replace('12andOver', '12 & 오버')
      }
      if (name?.indexOf('W1andUnder') >= 0){
        name = name.replace('W1andUnder', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} & 언더`)
      }
      if (name?.indexOf('W2andUnder') >= 0){
        name = name.replace('W2andUnder', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} & 언더`)
      }
      if (name?.indexOf('12andUnder') >= 0){
        name = name.replace('12andUnder', '12 & 언더')
      }

      if (name?.indexOf('1XandOver') >= 0){
        name = name.replace('1XandOver', '1X & 오버')
        return name;
      }
      if (name?.indexOf('XandOver') >= 0){
        name = name.replace('XandOver', '무 & 오버')
      }
      if (name?.indexOf('X2andOver') >= 0){
        name = name.replace('X2andOver', 'X2 & 오버')
      }
      if (name?.indexOf('1XandUnder') >= 0){
        name = name.replace('1XandUnder', '1X & 언더')
      }
      if (name?.indexOf('XAndUnder') >= 0){
        name = name.replace('XAndUnder', '무 & 언더')
      }
      if (name?.indexOf('X2andUnder') >= 0){
        name = name.replace('X2andUnder', 'X2 & 언더')
      }
      if (name?.indexOf('ormore') >= 0){
        name = name.replace('ormore', '골 또는 그이상')
      }
      if (name === 'AnyOther'){
        name = name.replace('AnyOther', '기타')
      }
      if (name === ''){
        name = bet.displayName
      }
      return name;
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets, type) {
      // console.log(this.$store.state.cart[this.cartType]);
      if (type === '보너스') {
        // let index = this.$store.state.cart[this.cartType].find(item => item.odds === event)
        // return !!index;
        return null;
      } else {
        let index = this.$store.state.sports.cart.find(item => item.betsSeq === bets.seq)
        return !!index;
      }
    },
    /**
     * 토글 액션
     * */
    toggleDetail: function (id) {
      this.toggle(this.openDetailBet, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailBet.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    // sortMarket:function(market){
    //   this.lists = this.originList.filter(e => e.marketSeq === market)
    // }
    movePage: function (page) {
      this.$router.push({path: `/${page}`})
    },
    uniqPeriod: function (marketSeq) {
      const periods = this.lists.filter(e => String(e.marketId) === String(marketSeq)).map(e => e.periodSequence);
      let set = new Set(periods);
      const uniqueArr = [...set];
      uniqueArr.sort()
      return uniqueArr
    },
    arrLenDiv: function (arr, div = 4) {
      return Math.floor(arr.length / div) + 1
    },
    // 매치 상태 변경
    matchStats: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        if (String(result.EventId) === String(this.game.id)) {
          const score = result.Score;
          console.log('stats', result);
          this.game.homeResult = score.split(':')[0] || 0;
          this.game.awayResult = score.split(':')[1] || 0;
          this.game.currentPeriod = result.Period;
          if (result.RemainingTime) {
            this.game.remainTime = result.RemainingTime;
            setTimeout(()=> {
              this.game.remainTime = null;
            }, 2000)
          }
        }
      }
    },
    // 매치 상태 업데이트
    matchUpdate: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const matchData = results[r];
        // console.log('detailMatchUpdate', matchData);
        if (String(this.game.id) === String(matchData.Id)) {
          // console.log('thisGameUpdate');
          if (matchData.IsStarted) this.game.status = '진행';
          this.game.isVisible = matchData.IsVisible ? 'y' : 'n';
          this.game.isSuspended = matchData.IsSuspended ? 'y' : 'n';
          // if (matchData.MatchStatus === 2){
          // }
        }
      }
    },
    // 인플레이 경기 자세히 내에 해당 폴더 변경
    updateData: function (data) {
      const result = JSON.parse(data);
      const gameMarket = result.filter(e => String(e.MatchId) === (this.game.id))
      if (gameMarket.length > 0) {
        gameMarket.map(m => {
          const folder = this.lists.find(e => String(e.id) === String(m.Id));
          if (folder) {
            folder.isVisible = m.IsVisible ? 'y' : 'n';
            folder.isSuspended = m.IsSuspended ? 'y' : 'n';
            m.Selections.map(s => {
              const bet = folder.bets.find(b => String(b.id) === String(s.Id));
              bet.price = s.Price;
              const cartItem = this.$store.state.sports.cart.find(e => String(e.betsId) === String(s.Id));
              if (cartItem) {
                cartItem.odds =  Number(s.Price).toFixed(2);
                cartItem.betsStatus = s.IsSuspended ? 'y' : 'n';
                cartItem.betsUpdated = true;
              }
            })
          }
        })
      }
    },
    folderClosed: function(seq, period){
      const data = {
        seq,
        period
      };
      const index = this.closed.findIndex(e => e.seq === seq && e.period === period)
      if (index >= 0) return this.closed.splice(index, 1);
      this.closed.push(data);
    }
  }
}
</script>

<style scoped>
.categorys2::-webkit-scrollbar {
  display: none; /* 크롬, 사파리, 오페라, 엣지 */
}
</style>
