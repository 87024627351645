<template>
  <div class="time" v-if="remainSeconds > 3600" style="color: #ffffff;">
    진행중
  </div>
  <div class="time" v-else style="color: #ffffff;">
    {{ minutes | twoDigits }}:{{ seconds | twoDigits }}
  </div>
</template>

<script>

import moment from "moment";

export default {
  props: ['startDate'],
  name: "CountDown",
  data: function(){
    return {
      minutes: '00',
      seconds: '00',
      remainSeconds: 3601
    }
  },
  created: function(){
    setInterval(this.remaining, 1000);
  },
  methods: {
    remaining: function () {
      let startTime = moment(this.startDate);
      let now = moment(); // 한국시간으로 치환
      let remaining = moment.duration(startTime - now);
      this.remainSeconds = startTime.diff(now, 'seconds');
      this.minutes = remaining.minutes();
      this.seconds = remaining.seconds();
    }
  }
}
</script>

<style scoped>

</style>
