<template>
  <div class="m-game3">
    <div class="title" style="margin: 5px 0px;">
      <div class="left">
        <div class="subj type1">※ 이스포츠 베팅 주의사항 ※</div>
      </div>
    </div>
    <div style="background-color: #222627;">
      <br>
      <p>베팅 전 필히 팀명, 세트, 기준점 확인후 베팅해주세요</p> <br>
      <p>회원님의 불찰로 인한 베팅의 경우 운영진은 책임지지 않습니다.</p> <br>
    </div>
    <template v-for="(league, leagueIndex) in gameList">
      <div class="title" style="margin: 5px 0 5px 0;" :key="'league' + leagueIndex">
        <div class="left">
          <div class="subj">
            <img class="game-logo" v-if="league.lname.indexOf('LOL') > -1" src="@/assets/img/ico/esports/lol.png" style="margin-right: 8px;" />
            <img class="game-logo" v-else-if="league.lname.indexOf('SC') > -1" src="@/assets/img/ico/esports/sc.png" style="margin-right: 8px;" />
            <img class="game-logo" v-else-if="league.lname.indexOf('CS') > -1" src="@/assets/img/ico/esports/cs.png" style="margin-right: 8px;" />
            <img class="game-logo" v-else-if="league.lname.indexOf('철권') > -1" src="@/assets/img/ico/esports/tk.png" style="margin-right: 8px;" />
            <img class="game-logo" v-else-if="league.lname.indexOf('Valorant') > -1" src="@/assets/img/ico/esports/vr.png" style="margin-right: 8px;" />
            <img class="game-logo" v-else-if="league.lname.indexOf('e스포츠') > -1" src="@/assets/img/ico/esports/alarm.png" style="margin-right: 8px;" />
            <img class="game-logo" v-else-if="league.lname.indexOf('스트리트') > -1" src="@/assets/img/ico/esports/sf.png" style="margin-right: 8px;" />
            {{ league.lname }}
          </div>
        </div>
        <!-- 여기 시간 어떻게 할지 고민해야함 -->
        <!-- 가장빠른 시간 추출 메서드 만들어서 띄울지 -->
        <!-- 어떻게든 내려서 각 폴더에 박을지 -->
        <!-- 일단 가장 빠른 시간([0][0][0]) -->
        <!-- 더 임박한 경기 있는 리그가 알아서 기어올라오겠지 -->
        <div class="right">{{ league.fixtures[0].earliestDate | formatDate1('MM-DD HH:mm') }}</div>
      </div>
      <template v-for="(fixture, fixtureIndex) in league.fixtures">
        <template v-for="(game, gameIndex) in fixture.games">
          <template v-for="(market, marketIndex) in game.markets">
            <template v-for="(folder, folderIndex) in market.folders">
              <div class="game-item" :key="'league(' + leagueIndex + ')-' + 'fixtureBox-' + fixtureIndex + '-' + gameIndex + '-' + marketIndex + '-' + folderIndex" v-show="(gameIndex === 0 && marketIndex === 0 && folderIndex === 0) || checkArray(fixture)">
                <template>
                  <dl :class="{'active': checkCart(folder, 'home')}" v-on:click="addCart(folder, 'home')">
                    <!-- 종목 -->
                    <dt style="width: 60%;">
                      {{ folder.hname }}
                    </dt>
                    <!-- 배당  -->
                    <dd>
                      <template v-if="!isLocked(folder, 'oh')">
                        <img v-if="folder.mc === '핸디캡' && !checkCart(folder, 'home')" style="width: 8px; margin-right: 8px;" src="@/assets/img/ico/red/h.png" alt="">
                        <img v-if="folder.mc === '핸디캡' && checkCart(folder, 'home')" style="width: 8px; margin-right: 8px;" src="@/assets/img/ico/white/h.png" alt="">
                        <img v-if="folder.mc === '오버언더' && !checkCart(folder, 'home')" style="width: 9px; height: 8px; margin-right: 8px;" src="@/assets/img/ico/m_ico_up.png" alt="">
                        <img v-if="folder.mc === '오버언더' && checkCart(folder, 'home')" style="width: 9px; height: 8px; margin-right: 8px;" src="@/assets/img/ico/m_ico_up.png" alt="">
                        <span >{{ folder.oh }}</span>
                      </template>
                      <span v-else class="ib" style="margin-right: 4px;">
                        <img style="width: 9px; margin-top: -3px" src="@/assets/img/ico/ico_secret.png" />
                      </span>
                    </dd>
                  </dl>
                  <div class="vs">
                    {{ folder.baseline === "1X2" ? "VS" : folder.baseline }}
                  </div>
                  <dl :class="{'active': checkCart(folder, 'away')}" v-on:click="addCart(folder, 'away')">
                    <!-- 종목 -->
                    <dt style="width: 60%; text-align: right;">
                      {{ folder.aname }}
                    </dt>
                    <!-- 배당  -->
                    <dd>
                      <template v-if="!isLocked(folder, 'oa')">
                        <span >{{ folder.oa }}</span>
                        <img v-if="folder.mc === '핸디캡' && !checkCart(folder, 'away')" style="width: 8px; margin-left: 8px;" src="@/assets/img/ico/red/h.png" alt="">
                        <img v-if="folder.mc === '핸디캡' && checkCart(folder, 'away')" style="width: 8px; margin-left: 8px;" src="@/assets/img/ico/white/h.png" alt="">
                        <img v-if="folder.mc === '오버언더' && !checkCart(folder, 'away')" style="width: 9px; height: 8px; margin-left: 8px;" src="@/assets/img/ico/m_ico_down.png" alt="">
                        <img v-if="folder.mc === '오버언더' && checkCart(folder, 'away')" style="width: 9px; height: 8px; margin-left: 8px;" src="@/assets/img/ico/m_ico_down.png" alt="">
                      </template>
                      <span v-else class="ib" style="margin-left: 4px;">
                        <img style="width: 9px; margin-top: -3px" src="@/assets/img/ico/ico_secret.png" />
                      </span>
                    </dd>
                  </dl>
                  <button
                      class="btn-val"
                      v-if="gameIndex === 0 && marketIndex === 0 && folderIndex === 0 && fixture.folderCnt > 1"
                      @click="toggleDetail(fixture)">{{ checkArray(fixture) ? "접기" : "+ " + String(Number(fixture.folderCnt) - 1) }}
                  </button>
                  <button v-else class="btn-bet">배팅</button>
                </template>
              </div>
            </template>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>

export default {
  name: "MFolderWrapper",
  props: ['lists', 'cartType', 'checkCart', 'addCart', 'checkArray', "openDetailBet", "toggleDetail", "isLocked"],
  components: {
    // MKoreanPrice
  },
  data: function () {
    return {
      gameList : []
    }
  },
  watch: {
    lists: {
      handler(newVal) {
        this.gameList = newVal.slice();
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<style scoped>
.vs img {
  margin-top: -3px !important;
}
.game-logo {
  margin-left: 10px;
  max-height: 25px;
  width: auto;
  /* max-width: 25px;
  height: auto; */
}

</style>
