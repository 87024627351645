import axios from "axios";
import store from "../index";

const API_URL = process.env.VUE_APP_API_URL;
const getDefaultState = () => {
  return {
    originList: [],
    cart: [],
  };
};

const state = getDefaultState();

const getters = {
  //카트 안에 담긴 배당내역의 총합
  ESPORTS_TOTAL_ODDS: function (state) {
    try {
      return Math.floor(state.cart.reduce((prev, curr) => prev * Number(curr.odds), 1) * 100 + 1e-8) / 100;
    } catch (error) {
      // 에러 발생 대비로 캐치블럭에 변경정 로직
      return state.cart.reduce((prev, curr) => prev * Number(curr.odds), 1).toFixed(2);
    }
  },
};

const actions = {
  GET_ESPORTS_LIST({ commit }) {
    return axios
      .post(`${API_URL}/game/esports`)
      .then((response) => {
        if (response.data?.payload?.list) {
          const sortedData = response.data.payload.list;
          commit("SET_ESPORTS_LIST", sortedData);
        }
      })
      .catch((error) => {
        console.error("🔥 API 호출 에러:", error);
      });
  },

  GET_ESPORTS_LIST1({ commit }) {
    return axios.post(`${API_URL}/game/esports`, {}, { responseType: "json" }).then((response) => {
      if (response.data) {
        try {
          const parsedData = JSON.parse(response.data);
          console.log("🔥 Vue에서 JSON.parse() 후 데이터:", parsedData);
        } catch (e) {
          console.error("🔥 Vue에서 JSON 파싱 오류:", e);
        }
        commit("SET_ESPORTS_LIST", response.data?.payload.list);
      }
      return response;
    });
  },
  ADD_CART_ESPORTS_ITEM({ state, commit }, obj) {
    const checkFolderCount = store.getters["LEVEL_SETTING"].prematchSetting.maxFolders;

    const cartInItemCnt = state.cart.filter((e) => e.betType !== "보너스").length;
    if (checkFolderCount <= cartInItemCnt) return alert(`최대가능 폴더수는 ${checkFolderCount}개 입니다.`);

    const checkMaxOdds = store.getters["LEVEL_SETTING"].prematchSetting.maxOdds;
    if (Number(getters.ESPORTS_TOTAL_ODDS) * Number(obj.odds) > Number(checkMaxOdds)) return alert("최대배당을 초과하셨습니다.");
    // 카트에 동일경기 아이템이 추가되었을때
    const equalCart = state.cart.find((item) => item.gameSeq === obj.gameSeq);
    if (equalCart) {
      return commit("POP_ITEM_ESPORTS_CART_BY_FOLDER", obj); // 있을시 삭제
    }

    const cartItem = state.cart.find((item) => item.gameSeq === obj.gameSeq && item.betSide === obj.betSide);
    if (!cartItem) {
      commit("PUSH_ESPORTS_ITEM_CART", obj);
    } else {
      commit("POP_ITEM_ESPORTS_CART", obj); // 있을시 삭제
    }
  },
  DELETE_CART_ESPORTS_ITEM({ commit }, obj) {
    commit("POP_ITEM_ESPORTS_CART", obj); // 있을시 삭제
  },
  DELETE_CART_ESPORTS_ITEM_SEQ({ commit }, gameSeq) { 
    commit("POP_ITEM_ESPORTS_CART_BY_SEQ", gameSeq); // 있을시 삭제
  },
  DELETE_CART_ITEM_GAME({ commit }, gameId) {
    commit("POP_ITEM_ESPORTS_CART_BY_GAME", gameId); // 있을시 삭제
  },
  ALL_CLEAR_ESPORTS_ITEM({ commit }) {
    return commit("RESET_ESPORTS_CART");
  },
};

const mutations = {
  SET_ESPORTS_LIST(state, payload) {
    state.originList = payload;
  },

  PUSH_ESPORTS_ITEM_CART(state, obj) {
    state.cart.push(obj);
  },
  POP_ITEM_ESPORTS_CART_BY_FOLDER(state, obj) {
    const index = state.cart.findIndex((i) => i.gameSeq === obj.gameSeq);
    if (index === -1) return (state.cart = [...state.cart, obj]);
    return state.cart.splice(index, 1);
  },
  POP_ITEM_ESPORTS_CART(state, obj) {
    const index = state.cart.findIndex((i) => i.betsSeq === obj.betsSeq);
    if (index === -1) return (state.cart = [...state.cart, obj]);
    return state.cart.splice(index, 1);
  },
  POP_ITEM_ESPORTS_CART_BY_GAME(state, gameId) {
    const index = state.cart.findIndex((i) => i.gameId === gameId);
    if (index === -1) {
      state.cart = [...state.cart];
      return;
    }
    return state.cart.splice(index, 1);
  },
  POP_ITEM_ESPORTS_CART_BY_SEQ(state, gameSeq) {
    const index = state.cart.findIndex((i) => i.gameSeq === gameSeq);
    if (index === -1) {
      state.cart = [...state.cart];
      return;
    }
    return state.cart.splice(index, 1);
  },
  RESET_ESPORTS_CART(state) {
    state.cart = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
