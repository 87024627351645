var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isMobile)?_c('div',{staticClass:"main-content"},[_c('h1',[_vm._v("나의 쿠폰")]),_c('div',{staticClass:"table-container"},[_c('h2',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("스포츠 프리벳 "+_vm._s(_vm.user.freeBets.length)+"장")]),_c('table',[_vm._m(0),_c('tbody',_vm._l((_vm.freebetList),function(freebet){return _c('tr',{key:'freebet'+ freebet.seq},[_c('td',[_vm._v(_vm._s(freebet.description))]),_c('td',[_vm._v(_vm._s(_vm._f("makeComma")(freebet.amount))+"원")]),_c('td',[_vm._v(_vm._s(_vm._f("makeComma")(freebet.win_amount))+"원")]),_c('td',[_vm._v(_vm._s(_vm._f("makeComma")(freebet.expected_win_amount))+"원")]),_c('td',{staticClass:"status-pending",class:{
                      'status-pending': freebet.status === '대기',
                      'status-success': freebet.status === '당첨',
                      'status-failed': freebet.status === '낙첨',
                      }},[_vm._v(_vm._s(freebet.status))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(freebet.granted_at,'YYYY-MM-DD HH:mm:ss')))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(freebet.expiration_at,'YYYY-MM-DD HH:mm:ss')))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(freebet.used_at,'YYYY-MM-DD HH:mm:ss')))]),_c('td',[_vm._v(_vm._s(freebet.bet_code))]),_c('td',[_c('button',{staticClass:"btn-select",on:{"click":function($event){return _vm.$router.push({ name: '자유게시판-글쓰기', query: { code: freebet.bet_code }})}}},[_vm._v("선택")])])])}),0)])]),_c('div',{staticClass:"flex-container"},[_c('div',{staticClass:"flex-item"},[_c('div',{staticClass:"table-container"},[_c('h2',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("로또 응모권 "+_vm._s(_vm.user.lotto)+" 장")]),_c('table',[_vm._m(1),_c('tbody',_vm._l((_vm.lottoList),function(lotto){return _c('tr',{key:'lotto' + lotto.seq},[_c('td',[_vm._v(_vm._s(lotto.lottoIndex))]),_c('td',{staticClass:"bet-res"},_vm._l((lotto.number.split(',')),function(row2,index2){return _c('span',{key:'lottoListNumber' + _vm.index +index2,class:{
                                  'bg-1' : Number(row2) <= 10 && Number(row2) > 0,
                                  'bg-10' : Number(row2) <= 20 && Number(row2) > 10,
                                  'bg-20' : Number(row2) <= 30 && Number(row2) > 20,
                                  'bg-30' : Number(row2) <= 40 && Number(row2) > 30,
                                  'bg-40' : Number(row2) > 40,
                                },staticStyle:{"margin-right":"5px"}},[_vm._v(" "+_vm._s(row2)+" ")])}),0),_c('td',{staticClass:"bet-res"},_vm._l((lotto.hitNumber?.split(',')),function(row2,index2){return _c('span',{key:'lottoListNumber' + _vm.index +index2,class:{
                                  'bg-1' : Number(row2) <= 10 && Number(row2) > 0,
                                  'bg-10' : Number(row2) <= 20 && Number(row2) > 10,
                                  'bg-20' : Number(row2) <= 30 && Number(row2) > 20,
                                  'bg-30' : Number(row2) <= 40 && Number(row2) > 30,
                                  'bg-40' : Number(row2) > 40,
                                },staticStyle:{"margin-right":"5px"}},[_vm._v(" "+_vm._s(row2)+" ")])}),0),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(lotto.createdAt,'YYYY-MM-DD HH:mm:ss')))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(lotto.updatedAt,'YYYY-MM-DD HH:mm:ss')))]),_c('td',[_c('button',{staticClass:"btn-select",on:{"click":function($event){return _vm.$router.push({ name: '자유게시판-글쓰기', query: { lottoSeq: lotto.seq }})}}},[_vm._v("선택")])])])}),0)])])]),_c('div',{staticClass:"flex-item"},[_c('div',{staticClass:"table-container"},[_c('h2',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("룰렛 쿠폰 "+_vm._s(_vm.user.roulette)+"장")]),_c('table',[_vm._m(2),_c('tbody',_vm._l((_vm.rouletteList),function(roulette){return _c('tr',{key:'roulette' + roulette.seq,staticStyle:{"height":"47.5px"}},[_c('td',[_vm._v(_vm._s(roulette.content))]),_c('td',[_vm._v(_vm._s(roulette.cnt))]),_c('td',[_vm._v(_vm._s(roulette.after))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(roulette.createdAt,'YYYY-MM-DD HH:mm:ss')))])])}),0)])])])])]):_c('div',{staticClass:"content"},[_c('h1',[_vm._v("나의 쿠폰")]),_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"tab active"},[_vm._v("스포츠 프리벳")]),_c('div',{staticClass:"tab"},[_vm._v(_vm._s(_vm.user.freeBets.length)+"장")])]),_c('table',{staticClass:"coupon-list"},[_vm._l((_vm.freebetList),function(freebet){return [_c('tr',{key:'freebet'+ freebet.seq},[_c('td',{staticStyle:{"border":"0 solid"}},[_vm._v(_vm._s(freebet.description))]),_c('td',{staticStyle:{"border":"0 solid"}},[_vm._v(_vm._s(_vm._f("makeComma")(freebet.amount)))]),_c('td',{class:{
                'status-success' : freebet.status === '당첨', 
                'status-failed': freebet.status === '낙첨'},staticStyle:{"border":"0 solid"}},[_vm._v(_vm._s(freebet.status))]),_c('td',{staticStyle:{"border":"0 solid"}},[_c('div',{staticClass:"share-button",on:{"click":function($event){return _vm.openFreeBet(freebet.seq)}}},[_vm._v("상세내역")])]),_vm._m(3,true)]),[(_vm.opendDetailsFreebet.includes(freebet.seq))?_c('tr',{key:'freebetDetail'+ freebet.seq},[_c('td',{attrs:{"colspan":"5"}},[_c('div',{staticClass:"coupon-item"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"label"},[_vm._v("보너스명:")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(freebet.description))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"label"},[_vm._v("금액:")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("makeComma")(freebet.amount))+"원")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"label"},[_vm._v("예상당첨금액:")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("makeComma")(freebet.expected_win_amount))+"원")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"label"},[_vm._v("당첨금액:")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("makeComma")(freebet.win_amount))+"원")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"label"},[_vm._v("상태:")]),_c('div',{staticClass:"status",class:{ 
                          'value': freebet.status === '대기',
                          'success': freebet.status === '당첨', 
                          'fail': freebet.status === '낙첨'}},[_vm._v(_vm._s(freebet.status))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"label"},[_vm._v("베팅코드:")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(freebet.bet_code))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"label"},[_vm._v("지급시간:")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("formatDate")(freebet.granted_at,'YYYY-MM-DD HH:mm:ss')))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"label"},[_vm._v("유효기간:")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("formatDate")(freebet.expiration_at,'YYYY-MM-DD HH:mm:ss')))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"label"},[_vm._v("사용시간:")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("formatDate")(freebet.used_at,'YYYY-MM-DD HH:mm:ss')))])]),_c('div',{staticClass:"share-button",on:{"click":function($event){return _vm.$router.push({ name: '자유게시판-글쓰기', query: { code: freebet.bet_code }})}}},[_vm._v("공유")])])])]):_vm._e()]]})],2),_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"tab active"},[_vm._v("로또 응모권")]),_c('div',{staticClass:"tab"},[_vm._v(_vm._s(_vm.user.lotto)+"장")])]),_c('div',{staticClass:"lotto-list"},[_c('table',[_vm._m(4),_vm._l((_vm.lottoList),function(lotto){return _c('tr',{key:'lotto' + lotto.seq},[_c('td',[_vm._v(_vm._s(lotto.lottoIndex))]),_c('td',{staticClass:"bet-res"},_vm._l((lotto.number.split(',')),function(row2,index2){return _c('span',{key:'lottoListNumber' + _vm.index +index2,class:{
                        'bg-1' : Number(row2) <= 10 && Number(row2) > 0,
                        'bg-10' : Number(row2) <= 20 && Number(row2) > 10,
                        'bg-20' : Number(row2) <= 30 && Number(row2) > 20,
                        'bg-30' : Number(row2) <= 40 && Number(row2) > 30,
                        'bg-40' : Number(row2) > 40,
                      },staticStyle:{"margin-right":"5px"}},[_vm._v(" "+_vm._s(row2)+" ")])}),0),_c('td',{staticClass:"bet-res"},_vm._l((lotto.hitNumber?.split(',')),function(row2,index2){return _c('span',{key:'lottoListNumber' + _vm.index +index2,class:{
                        'bg-1' : Number(row2) <= 10 && Number(row2) > 0,
                        'bg-10' : Number(row2) <= 20 && Number(row2) > 10,
                        'bg-20' : Number(row2) <= 30 && Number(row2) > 20,
                        'bg-30' : Number(row2) <= 40 && Number(row2) > 30,
                        'bg-40' : Number(row2) > 40,
                      },staticStyle:{"margin-right":"5px"}},[_vm._v(" "+_vm._s(row2)+" ")])}),0),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(lotto.createdAt,'YYYY-MM-DD HH:mm:ss')))]),_c('td',[_c('div',{staticClass:"share-button",on:{"click":function($event){return _vm.$router.push({ name: '자유게시판-글쓰기', query: { lottoSeq: lotto.seq }})}}},[_vm._v("선택")])])])})],2)]),_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"tab active"},[_vm._v("룰렛 쿠폰")]),_c('div',{staticClass:"tab"},[_vm._v(_vm._s(_vm.user.roulette)+"장")])]),_c('div',{staticClass:"roulette-list"},[_c('table',[_vm._m(5),_vm._l((_vm.rouletteList),function(roulette){return _c('tr',{key:'roulette' + roulette.seq},[_c('td',[_vm._v(_vm._s(roulette.content))]),_c('td',[_vm._v(_vm._s(roulette.cnt))]),_c('td',[_vm._v(_vm._s(roulette.after))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(roulette.createdAt,'YYYY-MM-DD HH:mm:ss')))])])})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("보너스명")]),_c('th',[_vm._v("프리벳금액")]),_c('th',[_vm._v("예상적중금액")]),_c('th',[_vm._v("당첨금액")]),_c('th',[_vm._v("상태")]),_c('th',[_vm._v("지급시간")]),_c('th',[_vm._v("유효기간")]),_c('th',[_vm._v("사용시간")]),_c('th',[_vm._v("베팅코드")]),_c('th',[_vm._v("자유게시판")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("회차")]),_c('th',[_vm._v("응모번호")]),_c('th',[_vm._v("당첨번호")]),_c('th',[_vm._v("지급시간")]),_c('th',[_vm._v("응모시간")]),_c('th',[_vm._v("자유게시판")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("내용")]),_c('th',[_vm._v("쿠폰")]),_c('th',[_vm._v("보유쿠폰")]),_c('th',[_vm._v("시각")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"border":"0 solid"}},[_c('div',{staticClass:"share-button"},[_vm._v("선택")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("회차")]),_c('th',[_vm._v("응모번호")]),_c('th',[_vm._v("당첨번호")]),_c('th',[_vm._v("응모시간")]),_c('th',[_vm._v("자유게시판")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("내용")]),_c('th',[_vm._v("쿠폰")]),_c('th',[_vm._v("보유쿠폰")]),_c('th',[_vm._v("시간")])])
}]

export { render, staticRenderFns }