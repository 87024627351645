<template>
  <section v-if="!this.$store.state.page.isMobile">
    <div class="wrap">
      <h2 class="subTitle1"><span>행운을 드리는 </span>룰렛 이벤트!</h2>
      <div class="inner_flex">
        <div data-v-339c7226="" class="game-side m_none mt0" style="float: unset; min-width: 230px;">
          <div class="list">
           <router-link to="/event/lotto">로또</router-link>
            <router-link to="/event/chulsuk">출석부</router-link>
            <router-link to="/event/roulette" class="active">룰렛</router-link>
          </div>
        </div>
        <div class="contents_wrap">
          <div class="roll">
            <div class="roll-out"><img src="@/assets/img/etc/roulette_out.png"></div>
            <div class="roll-in"
             :class="{freeze: freeze}"
             :style="`transform: rotate(${wheelDeg}deg)`"
            ><img src="@/assets/img/etc/roulette_in.png"></div>
            <div class="roll-btn" style="cursor: pointer;" @click="onClickRotate"><img src="@/assets/img/etc/roulette_btn.png"></div>
          </div>

<!--          <p class="e01">※, 포인트를 제외한 첫충20% , 아메리카노 한폴낙 면제권, BBQ치킨 필히 고객센터 첨부 바랍니다.</p>-->
          <table class="listTable02">
            <colgroup>
              <col width="120" />
              <col width="" />
              <col width="124" />
              <col width="124" />
              <col width="100" />
            </colgroup>
            <tr>
              <th>번호</th>
              <th>당첨결과</th>
              <th>시간</th>
<!--              <th>게시판첨부</th>-->
<!--              <th>선택</th>-->
            </tr>
            <tr v-for="(row, index) in lists" :key="'rouletteHistory' + index">
              <td>56</td>
              <td class="al"><a href="javascript:void(0)">{{ row.content }}</a></td>
              <td>{{ row.createdAt | formatDate('YYYY-MM-DD') }}</td>
<!--              <td><a href="javascript:void(0)" class="btn01">첨부</a></td>-->
<!--              <td><input type="checkbox" /></td>-->
            </tr>

          </table>

          <Pagination v-if="pagination" :req-data="reqData" :payload="payload" :pagination="pagination"></Pagination>

        </div>

        <div class="check_info_text">
<!--          <div>-->
<!--            <h3>로또 이벤트 참여하는 방법</h3>-->
<!--            <p>-->
<!--								<span>-->
<!--									1. 일요일부터 토요일 발표전까지 하루에 5만원이상,일주일 총 50만원 이상 충전시 응모권 지급-->
<!--								</span>-->
<!--              <span>-->
<!--									2. 45개 숫자 중 6개 번호 선택-->
<!--								</span>-->
<!--              <span>-->
<!--									3. 추첨을 통해 결과 확인-->
<!--								</span>-->
<!--              <span>-->
<!--									- 양방 및 악성배터분들은 로또이벤트 참여가 제한될 수 있습니다.-->
<!--								</span>-->
<!--              <span>-->
<!--									- 한 회차 당 한번의 참여만 가능합니다.-->
<!--								</span>-->
<!--              <span>-->
<!--									- 당첨 후 반드시 고객센터로 발표 당일 23시 이전에 문의, 23시 이후 문의시 이벤트 실격 처리-->
<!--								</span>-->
<!--              <span>-->
<!--									- 위의 규정 위반시 실격처리 됩니다-->
<!--								</span>-->
<!--              <span>-->
<!--									당첨금<br>-->
<!--									당첨인원에 관계없이 모두 지급<br>-->
<!--									4개이상 번호 적중 시 당첨 > 500만원 바로 환전가능-->
<!--								</span>-->
<!--            </p>-->
<!--          </div>-->
        </div>
      </div>
    </div>


  </section>
  <div v-else >
    <div class="header2">
      <div class="left">
        <a @click="$router.push('/main')" class="btn-prev"></a>
      </div>
      <h2>행운의 룰렛</h2>
    </div>
    <div class="tabs1">
     <a @click="$router.push('/event/lotto')">로또</a>
      <a @click="$router.push('/event/chulsuk')">출석부</a>
      <a @click="$router.push('/event/roulette')" class="active">룰렛</a>
    </div>
    <div class="event">
      <div class="roll">
        <div class="roll-out"><img src="@/assets/img/etc/roulette_out.png"></div>
        <div class="roll-in"
             :class="{freeze: freeze}"
             :style="`transform: rotate(${wheelDeg}deg)`"
        >
          <img src="@/assets/img/etc/roulette_in.png">
        </div>
        <div class="roll-btn" style="cursor: pointer;" @click="onClickRotate">
          <img src="@/assets/img/etc/roulette_btn.png">
        </div>
      </div>
<!--      <p class="e01">※, 포인트를 제외한 첫충20% , 아메리카노 한폴낙 면제권, BBQ치킨 필히 고객센터 첨부 바랍니다.</p>-->

      <table class="listTable02">
        <colgroup>
          <col width="120">
          <col width="">
          <col width="124">
          <col width="124">
<!--          <col width="100">-->
        </colgroup>
        <tbody><tr>
          <th>번호</th>
          <th>당첨결과</th>
          <th>시간</th>
<!--          <th>게시판첨부</th>-->
<!--          <th>선택</th>-->
        </tr>
        <tr v-for="(row, index) in lists" :key="'rouletteHistory' + index">
          <td>{{ index | makeIndex(payload.page, payload.size) }}</td>
          <td class="al"><a href="javascript:void(0)">{{ row.content }}</a></td>
          <td>{{ row.createdAt | formatDate('YYYY.MM.DD HH:mm:Ss') }}</td>
<!--          <td><a href="javascript:void(0)" class="btn01">첨부</a></td>-->
<!--          <td><input type="checkbox"></td>-->
        </tr>

        </tbody>
      </table>
    </div>


    <div class="list-days2">
      <Pagination v-if="pagination" :req-data="reqData" :payload="payload" :pagination="pagination"></Pagination>
    </div>
  </div>
</template>

<script>
// import SideBox from '../../components/SideBox'
import Pagination from '../../components/Pagination02'

export default {
  name: "event",
  components: {
    // SideBox,
    Pagination
  },
  data: function(){
    return {
      type: 'roulette',
      freeze: false,
      rolling: false,
      wheelDeg: 0,
      lists: null,
      pagination: {},
      payload: {
        size: 5,
        page: 1
      }
    }
  },
  created() {
    this.reqData(this.payload)
  },
  methods: {
    onClickRotate() {
      if (this.rolling) {
        return;
      }
      // const result = Math.floor(Math.random() * this.prizeList.length);
      this.roll();
    },
    roll() {
      this.rolling = true;
      const { wheelDeg } = this;
      this.$store.dispatch('GET_ROULETTE_DATA')
          .then(data => {
            const result = data.data;
            this.wheelDeg =
                wheelDeg -
                wheelDeg % 360 +
                6 * 360 +
                (360 - 360/ 10 * result.payload.index) - 18;
            this.rolling = false;
            this.$store.dispatch('ME');
            if (!this.rolling) {
              setTimeout(()=> {
                alert(result.msg)
                this.reqData(this.payload)
              }, 4500)
            }
          }).catch(err => {
        alert(err.response.data.error);
      })
    },
    reqData: function(payload){
      this.loading = false;
      return this.$store.dispatch('GET_ROULETTE_LIST', { payload })
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.pagination = result.payload.pagination;
            console.log(this.pagination)
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
  },
}
</script>

<style scoped>
.roll-in {
  transition: transform 4s ease-in-out;

  &.freeze {
     transition: none;
     background: red;
   }
}

/*@keyframes roll-in {*/
/*  from { transform: rotate(0deg); }*/
/*  to { transform: rotate(360deg); }*/
/*}*/

</style>
